import React from 'react';
import { ChatDetailType } from '../../../types/api/chat/ChatTypes';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { RoomCardType } from '../../../types/api/room/RoomTypes';
import { UserDetailType } from '../../../types/api/user/UserTypes';
import ChatGrid from '../../organisms/grid/chatGrid/ChatGrid';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import RoomCardGrid from '../../organisms/grid/roomCardGrid/RoomCardGrid';
import IdeaTypeButtonsGrid from '../../organisms/radio/IdeaType/IdeaTypeButtonsGrid';
import MypageNavTabs from '../../organisms/tabs/MypageNavTabs';
import UserInfoForm from '../../organisms/textfield/form/UserInfoForm';
import './MyPageTemplate.css';

interface HomeTemplateProps {
  user: UserDetailType;
  user_ideas: IdeaCardType[];
  rooms: RoomCardType[];
  chat_room: RoomCardType;
  chats: ChatDetailType[];
  mode?: string;
  title?: string;
  chat_user_id: string | null;
  page?: number;
  max_page?: number;
}

const MyPageTemplateSP: React.FC<HomeTemplateProps> = (props) => {
  return (
    <div className="mypage-template">
      <MypageNavTabs mode={props.mode} />
      <main className={`mypage-template-main sp`} style={{ width: '95%' }}>
        {props.mode === 'my' ? (
          <>
            <IdeaTypeButtonsGrid columns={2} />
            <IdeaCardGrid
              isSP
              card_text_size="extra-small"
              columns={2}
              ideas={props.user_ideas}
              page={props.page}
              max_page={props.max_page}
            />
          </>
        ) : props.mode === 'chat' ? (
          props.chat_user_id ? (
            <ChatGrid
              to_user_name="太郎"
              login_user_id="1"
              chats={props.chats}
              size="extra-small"
              only
            />
          ) : (
            <RoomCardGrid rooms={props.rooms} />
          )
        ) : props.mode === 'info' ? (
          <UserInfoForm columns={3} user={props.user} />
        ) : null}
      </main>
    </div>
  );
};

export default MyPageTemplateSP;
