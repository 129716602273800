import {
  fetchIdeaActivitiesProps,
  sendIdeaActivityProps,
} from './ideaActivityAPI.type';

// アイデアの活動報告を取得するAPI
export const fetchIdeaActivities = async (input: fetchIdeaActivitiesProps) => {
  const response = await fetch(`/api/ideas/${input.idea_id}/comments`);

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};

// アイデアの活動報告を送信するAPI
export const sendIdeaActivity = async (input: sendIdeaActivityProps) => {
  const response = await fetch(`/api/ideas/${input.idea_id}/activities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(input),
  });

  if (!response.ok) {
    // throw new Error(`Failed to send comment: ${response.statusText}`);
    alert('Input:\n' + String(JSON.stringify(input)));
  }

  return response.json();
};
