import { useSelector } from 'react-redux';
import {
  DUMMY_USER_DETAIL_DATA,
  MULTI_DUMMY_IDEA_CARD_DATA,
  MULTI_DUMMY_REQUEST_DETAIL_DATA,
} from '../../constants/dummy';
import { RootState } from '../../redux/store';
import { IdeaCardType } from '../../types/api/idea/IdeaTypes';
import { RequestDetailType } from '../../types/api/request/RequestTypes';
import { UserDetailType } from '../../types/api/user/UserTypes';
import IntroduceTemplatePC from '../templates/introduction/IntroduceTemplatePC';
import IntroduceTemplateSP from '../templates/introduction/IntroduceTemplateSP';

const IntroducePage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  const user: UserDetailType = DUMMY_USER_DETAIL_DATA;
  const requests: RequestDetailType[] = MULTI_DUMMY_REQUEST_DETAIL_DATA;
  const ideas: IdeaCardType[] = MULTI_DUMMY_IDEA_CARD_DATA;

  return isXS ? (
    <IntroduceTemplateSP
      size={'sp'}
      user={user}
      user_requests={requests}
      user_ideas={ideas}
    />
  ) : (
    <IntroduceTemplatePC
      size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
      user={user}
      user_requests={requests}
      user_ideas={ideas}
    />
  );
};

export default IntroducePage;
