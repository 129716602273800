import React from 'react';
import {
  IDEA_STORY_MAX_LENGTH,
  IDEA_TAGS_MAX_LENGTH,
  IDEA_TITLE_MAX_LENGTH,
} from '../../../../constants/max';
import useTextValuesMax from '../../../../hooks/useTextValuesMax';
import useTextValueWithNull from '../../../../hooks/useTextValueWidthNull';
import useTextfieldMax from '../../../organisms/textfield/useTextfieldMax.hooks';

interface IdeaFormState {
  request_id: string | null;
  title: string;
  story: string;
  tags: string[];
  generate: boolean;
  img: File | null;
}

interface IdeaFormErrors {
  title: string | null;
  story: string | null;
  tags: string | null;
}

interface IdeaFormHandlers {
  handleRequestIdChange: (value: string | null) => void;
  handleTitleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleStoryChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleTagsChange: (value: string) => void;
  handleChangeGenerate: () => void;
  handleChangeImg: (value: File | null) => void;
}

export const useIdeaPostForm = (
  init?: IdeaFormState,
): {
  values: IdeaFormState;
  errors: IdeaFormErrors;
  handlers: IdeaFormHandlers;
} => {
  // お題の値
  const { value: request_id, handleChange: handleRequestIdChange } =
    useTextValueWithNull(init?.request_id || null);

  // タイトルの値
  const {
    value: title,
    error: title_error,
    handleChange: handleTitleChange,
  } = useTextfieldMax(init?.title || '', IDEA_TITLE_MAX_LENGTH);

  // ストーリーの値
  const {
    value: story,
    error: story_error,
    handleChange: handleStoryChange,
  } = useTextfieldMax(init?.story || '', IDEA_STORY_MAX_LENGTH);

  // カテゴリーの値
  const {
    values: tags,
    error: tags_error,
    handleChange: handleTagsChange,
  } = useTextValuesMax(init?.tags || [], IDEA_TAGS_MAX_LENGTH);

  // 画像生成の値
  const [generate, setGenerate] = React.useState<boolean>(true);
  const handleChangeGenerate = () => setGenerate(!generate);

  // 画像の値
  const [img, setImg] = React.useState<File | null>(null);
  const handleChangeImg = (value: File | null) => setImg(value);

  const values: IdeaFormState = {
    request_id,
    title,
    story,
    tags,
    generate,
    img,
  };

  const errors: IdeaFormErrors = {
    title: title_error,
    story: story_error,
    tags: tags_error,
  };

  const handlers = {
    handleRequestIdChange,
    handleTitleChange,
    handleStoryChange,
    handleTagsChange,
    handleChangeGenerate,
    handleChangeImg,
  };

  return { values, errors, handlers };
};
