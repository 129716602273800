import { useSearchParams } from 'react-router-dom';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import {
  parseSearchSortQuery,
  searchPageSortQueryMap,
} from '../../../types/query/searchQuery';
import NavTextsMolecules from '../../molecules/text/nav/NavTexts.Molecules';

const IdeaSortNavTexts = () => {
  const { goToSearchPage } = usePageNavigation();
  const [searchParams] = useSearchParams();
  const sort = searchParams.get('sort');
  return (
    <NavTextsMolecules
      textOptions={Object.entries(searchPageSortQueryMap).map(
        ([sort, label]) => ({ label: label + 'アイデア', value: sort }),
      )}
      onClick={(value: string) =>
        goToSearchPage({ sort: parseSearchSortQuery(value) })
      }
      value={sort || ''}
    />
  );
};

export default IdeaSortNavTexts;
