import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import './IdeaApealAndStory.css';

interface TagsCheckboxGridProps {
  idea: IdeaDetailType;
}

const IdeaApealAndStory: React.FC<TagsCheckboxGridProps> = (props) => {
  const { idea } = props;
  return (
    <div className="idea-apeal-and-story-wrapper">
      <div className="idea-apeal-and-story-cell">
        <div className="idea-apealpoints-cell">
          {idea.apealPoints.length > 0 && (
            <NumAndTitle num={1} title={idea.apealPoints[0]} />
          )}
          {idea.apealPoints.length > 1 && (
            <NumAndTitle num={1} title={idea.apealPoints[1]} />
          )}
          {idea.apealPoints.length > 2 && (
            <NumAndTitle num={1} title={idea.apealPoints[2]} />
          )}
        </div>
        <BodyTextIons text={idea.description} size="small" />
      </div>
    </div>
  );
};

export default IdeaApealAndStory;
