import { useSelector } from 'react-redux';
import { DUMMY_IDEA_DETAIL_DATA } from '../../../constants/dummy';
import { RootState } from '../../../redux/store';
import { IdeaDetailType } from '../../../types/api/idea/IdeaTypes';
import IdeaEditTemplate from '../../templates/ideaEdit/IdeaEditTemplate';

const IdeaEditPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const idea: IdeaDetailType = DUMMY_IDEA_DETAIL_DATA;

  return (
    <IdeaEditTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      idea={idea}
    />
  );
};

export default IdeaEditPage;
