import { useCallback, useState } from 'react';

interface UseTextValuesProps {
  values: string[];
  error: string | null;
  handleChange: (newValue: string) => void;
  handleReset: () => void;
}

const useTextValuesMax = (
  init?: string[],
  max?: number,
): UseTextValuesProps => {
  const [values, setValues] = useState<string[]>(init ?? []);
  const [error, setError] = useState<string | null>(null);

  const handleChange = useCallback(
    (newValue: string) => {
      if (values.includes(newValue)) {
        setError(null);
        setValues((prevValues) =>
          prevValues.filter((v: string) => newValue !== v),
        );
      } else {
        if (max && values.length >= max) {
          setError('既に最大数に達しています');
          return;
        } else {
          setError(null);
          setValues((prevValues) => [...prevValues, newValue]);
        }
      }
    },
    [values],
  );

  const handleReset = useCallback(() => {
    setValues([]);
  }, []);

  return { values, error, handleChange, handleReset };
};

export default useTextValuesMax;
