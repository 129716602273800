import { sendIdeaCommentProps } from '../../../../api/idea/comment/ideaCommentAPI.type';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import { IdeaCommentType } from '../../../../types/api/idea/IdeaTypes';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import { CommentCardMolecules } from '../../../molecules/card/comment/CommentCard.Molecules';
import './IdeaComments.css';

interface IdeaCommentsProps {
  idea_id: string;
  idea_comments: IdeaCommentType[];
  login_user_img_src: string;
  login_user_name: string;
  onSendComment: (input: sendIdeaCommentProps) => void;
  isLoggedIn: boolean;
}

const IdeaComments: React.FC<IdeaCommentsProps> = (props) => {
  const { isLoggedIn } = props;
  const { value: comment, handleChange: handleChangeComment } = useTextfield();

  return (
    <div className="idea-comments-wrapper">
      <form className="idea-comments-form">
        <div className="idea-comments-form-top">
          <UserIconImgIons
            img_src={props.login_user_img_src}
            user_name={props.login_user_name}
            alt="user-icon"
            size="medium"
          />
          <DefaultTextFieldIons
            label={null}
            placeholder={
              isLoggedIn ? 'コメントを入力してください' : 'ログインしてください'
            }
            value={comment}
            onChange={handleChangeComment}
            error={null}
            design_type="underline"
            disabled={!isLoggedIn}
          />
        </div>
        <div className="idea-comments-form-buttons">
          {isLoggedIn ? (
            <BodyTextIonsClickable
              text="投稿する"
              onClick={() =>
                props.onSendComment({
                  idea_id: props.idea_id,
                  to_comment_id: null,
                  comment: comment,
                })
              }
            />
          ) : (
            <BodySubTextIons text="投稿する" />
          )}
        </div>
      </form>
      <div className="idea-comments-cell">
        {props.idea_comments.map((comment, i) => (
          <CommentCardMolecules
            key={i}
            comment={comment}
            onSendComment={props.onSendComment}
          />
        ))}
      </div>
    </div>
  );
};

export default IdeaComments;
