import { ChangeEvent, useState } from 'react';

type ValidateFunction = (value: string) => string | null;

interface UseTextfieldProps {
  value: string;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
}

const useTextfield = (
  init?: string,
  validate?: ValidateFunction,
): UseTextfieldProps => {
  const [value, setValue] = useState(init ?? '');
  const [error, setError] = useState<string | null>(null);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (validate) {
      const validationError = validate(newValue);
      setError(validationError);
    } else {
      setError(null);
    }
  };

  return { value, error, handleChange };
};

export default useTextfield;
