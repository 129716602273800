import { createSlice } from '@reduxjs/toolkit';

export interface RegisterPopupState {
  isOpen: boolean;
}

const initialState: RegisterPopupState = {
  isOpen: false,
};

const registerPopupSlice = createSlice({
  name: 'registerPopup',
  initialState,
  reducers: {
    openRegisterPopup: (state) => {
      state.isOpen = true;
    },
    closeRegisterPopup: (state) => {
      state.isOpen = false;
    },
    toggleRegisterPopup: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openRegisterPopup, closeRegisterPopup, toggleRegisterPopup } =
  registerPopupSlice.actions;
export default registerPopupSlice.reducer;
