import React from 'react';
import ImgAtoms from '../../atoms/img/Img.Atoms';
import { ImgAtomsProps } from '../../atoms/img/Img.type';
import './Img.Ions.css';

const RequestImgIons: React.FC<ImgAtomsProps> = React.memo((props) => {
  return (
    <ImgAtoms {...props} className={`request-img-ions ${props.className}`} />
  );
});

export default RequestImgIons;
