import { useSelector } from 'react-redux';
import { MULTI_DUMMY_IDEA_CARD_DATA } from '../../constants/dummy';
import { RootState } from '../../redux/store';
import HomeTemplate from '../templates/home/HomeTemplate';

const HomePage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  return (
    <HomeTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      top_ideas={MULTI_DUMMY_IDEA_CARD_DATA}
      main_ideas1={MULTI_DUMMY_IDEA_CARD_DATA}
      main_ideas2={MULTI_DUMMY_IDEA_CARD_DATA}
      main_ideas3={MULTI_DUMMY_IDEA_CARD_DATA}
    />
  );
};

export default HomePage;
