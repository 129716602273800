import { useCallback, useState } from 'react';

export const useImageUpload = (onImageUpload: (file: File | null) => void) => {
  const [isDragging, setIsDragging] = useState(false);

  const onDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const onDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      setIsDragging(false);

      const droppedFiles = Array.from(e.dataTransfer.files);
      const imageFiles = droppedFiles.filter((file) =>
        file.type.startsWith('image/'),
      );

      imageFiles.forEach((file) => {
        onImageUpload(file);
      });
    },
    [onImageUpload],
  );

  const onFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const selectedFiles = Array.from(e.target.files);
        selectedFiles.forEach((file) => {
          onImageUpload(file);
        });
      }
    },
    [onImageUpload],
  );

  const onDelete = useCallback(() => {
    onImageUpload(null);
  }, [onImageUpload]);

  return {
    isDragging,
    onDragOver,
    onDragLeave,
    onDrop,
    onFileSelect,
    onDelete,
  };
};
