import React from 'react';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { PurpleTitleTextIons } from '../../../ions/text/title/PurpleTitleText.Ions';
import './ReturnCard.Molecules.css';

type ReturnCardMoleculesProps = {
  idea_id: string;
  stage: number;
};

export const ReturnCardMolecules: React.FC<ReturnCardMoleculesProps> =
  React.memo((props) => {
    const { goToIdeaInvestPage } = usePageNavigation();
    let price: number;
    let title: string;
    let description: string;

    switch (props.stage) {
      case 1:
        price = 1000;
        title = 'IDEA実現後のリターン(小)';
        description = '例. 実現時に10%割引で購入可能(小)';
        break;
      case 2:
        price = 5000;
        title = 'IDEA実現後のリターン(中)';
        description = '例. 実現時に15%割引で購入可能(中)';
        break;
      case 3:
        price = 10000;
        title = 'IDEA実現後のリターン(大)';
        description = '例. 実現時に20%割引で購入可能(大)';
        break;
      default:
        price = 1000;
        title = 'IDEA実現後のリターン(小)';
        description = '例. 実現時に10%割引で購入可能(小)';
    }

    return (
      <div className={`return-card-molecules-container`}>
        <div className="return-card-molecules-header">
          <PurpleTitleTextIons text={'STAGE' + props.stage} size="small" />
          <BodyTextIons text={price + ' 円'} size="medium" />
        </div>
        <main className="return-card-molecules-main">
          <BodyTextBoldIons text={title} size="small" />
          <BodyTextBoldIons text={description} size="small" />
          <RoundWhiteButtonIons
            size="medium"
            onClick={() => goToIdeaInvestPage(props.idea_id)}
          >
            出資する
          </RoundWhiteButtonIons>
        </main>
      </div>
    );
  });
