import { createSlice } from '@reduxjs/toolkit';

export interface IdeaPostPopupState {
  isOpen: boolean;
}

const initialState: IdeaPostPopupState = {
  isOpen: false,
};

const ideaPopupSlice = createSlice({
  name: 'ideaPopup',
  initialState,
  reducers: {
    openIdeaPostPopup: (state) => {
      state.isOpen = true;
    },
    closeIdeaPostPopup: (state) => {
      state.isOpen = false;
    },
    toggleIdeaPostPopup: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { openIdeaPostPopup, closeIdeaPostPopup, toggleIdeaPostPopup } =
  ideaPopupSlice.actions;
export default ideaPopupSlice.reducer;
