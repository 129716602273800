import React from 'react';
import {
  IDEA_APEALPOINT_MAX_LENGTH,
  IDEA_STORY_MAX_LENGTH,
  IDEA_SUBTITLE_MAX_LENGTH,
  IDEA_TAGS_MAX_LENGTH,
  IDEA_TITLE_MAX_LENGTH,
} from '../../../constants/max';
import useTextValuesMax from '../../../hooks/useTextValuesMax';
import useTextfieldMax from '../../organisms/textfield/useTextfieldMax.hooks';

interface IdeaFormState {
  title: string;
  subTitle: string | null;
  story: string;
  tags: string[];
  apealPoint1: string | null;
  apealPoint2: string | null;
  apealPoint3: string | null;
  generate: boolean;
  img: string | File | null;
}

interface IdeaFormErrors {
  title: string | null;
  subTitle: string | null;
  story: string | null;
  tags: string | null;
  apealPoint1: string | null;
  apealPoint2: string | null;
  apealPoint3: string | null;
}

interface IdeaFormHandlers {
  handleTitleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleSubTitleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleStoryChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleTagsChange: (value: string) => void;
  handleApealPoint1Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleApealPoint2Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleApealPoint3Change: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  handleChangeGenerate: () => void;
  handleChangeImg: (value: File | null) => void;
}

export const useIdeaEditForm = (
  init?: IdeaFormState,
): {
  values: IdeaFormState;
  errors: IdeaFormErrors;
  handlers: IdeaFormHandlers;
} => {
  // タイトルの値
  const {
    value: title,
    error: title_error,
    handleChange: handleTitleChange,
  } = useTextfieldMax(init?.title ?? '', IDEA_TITLE_MAX_LENGTH);

  // サブタイトルの値
  const {
    value: subTitle,
    error: subTitle_error,
    handleChange: handleSubTitleChange,
  } = useTextfieldMax(init?.subTitle ?? '', IDEA_SUBTITLE_MAX_LENGTH);

  // ストーリーの値
  const {
    value: story,
    error: story_error,
    handleChange: handleStoryChange,
  } = useTextfieldMax(init?.story ?? '', IDEA_STORY_MAX_LENGTH);

  // カテゴリーの値
  const {
    values: tags,
    error: tags_error,
    handleChange: handleTagsChange,
  } = useTextValuesMax(init?.tags, IDEA_TAGS_MAX_LENGTH);

  // アピールポイント1~3の値
  const {
    value: apealPoint1,
    error: apealPoint1_error,
    handleChange: handleApealPoint1Change,
  } = useTextfieldMax(init?.apealPoint1 ?? '', IDEA_APEALPOINT_MAX_LENGTH);
  const {
    value: apealPoint2,
    error: apealPoint2_error,
    handleChange: handleApealPoint2Change,
  } = useTextfieldMax(init?.apealPoint2 ?? '', IDEA_APEALPOINT_MAX_LENGTH);
  const {
    value: apealPoint3,
    error: apealPoint3_error,
    handleChange: handleApealPoint3Change,
  } = useTextfieldMax(init?.apealPoint3 ?? '', IDEA_APEALPOINT_MAX_LENGTH);

  // 画像生成の値
  const [generate, setGenerate] = React.useState<boolean>(true);
  const handleChangeGenerate = () => setGenerate(!generate);

  // 画像の値
  const [img, setImg] = React.useState<File | null>(null);
  const handleChangeImg = (value: File | null) => setImg(value);

  const values: IdeaFormState = {
    title,
    story,
    subTitle,
    tags,
    apealPoint1: apealPoint1,
    apealPoint2: apealPoint2,
    apealPoint3: apealPoint3,
    generate,
    img,
  };

  const errors: IdeaFormErrors = {
    title: title_error,
    subTitle: subTitle_error,
    story: story_error,
    tags: tags_error,
    apealPoint1: apealPoint1_error,
    apealPoint2: apealPoint2_error,
    apealPoint3: apealPoint3_error,
  };

  const handlers = {
    handleTitleChange,
    handleSubTitleChange,
    handleStoryChange,
    handleTagsChange,
    handleApealPoint1Change,
    handleApealPoint2Change,
    handleApealPoint3Change,
    handleChangeGenerate,
    handleChangeImg,
  };

  return { values, errors, handlers };
};
