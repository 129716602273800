import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import ButtonAtoms from '../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../atoms/button/Button.type';
import './SettingButton.Ions.css';

const SettingButtonIons: React.FC<Omit<ButtonAtomsProps, 'children'>> =
  React.memo((props) => {
    return (
      <ButtonAtoms
        onClick={props.onClick}
        disabled={props.disabled}
        className={`setting-button-ions`}
      >
        {<MoreVertIcon className={`setting-button-icon-ions ${props.size}`} />}
      </ButtonAtoms>
    );
  });

export default SettingButtonIons;
