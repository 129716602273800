import { RoomCardType } from '../../../../types/api/room/RoomTypes';
import { RoomCardMolecules } from '../../../molecules/card/room/RoomCard.Molecules';
import './RoomCardGrid.css';

interface TagsCheckboxGridProps {
  rooms: RoomCardType[];
}

const RoomCardGrid: React.FC<TagsCheckboxGridProps> = (props) => {
  return (
    <div data-testid="room-card-grid" className={`room-card-grid-container `}>
      {props.rooms.map((room) => (
        <RoomCardMolecules
          user_id={room.to_user_id}
          room_id={room.room_id}
          user_name={room.to_user_name}
          user_img_src={room.to_user_img_src}
          last_message={room.last_message}
          last_created_at={room.last_message_time}
          unread_count={room.unread_count}
        />
      ))}
    </div>
  );
};

export default RoomCardGrid;
