import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import { IdeaCardMolecules } from '../../../molecules/card/idea/IdeaCard.Molecules';
import PageNationButtons from '../../../molecules/page/PageNationButtons';
import './IdeaCardGrid.css';

interface TagsCheckboxGridProps {
  columns: number;
  ideas: IdeaCardType[];
  card_text_size?: 'extra-small' | 'small';
  isSP: boolean;
  page?: number;
  max_page?: number;
}

const IdeaCardGrid: React.FC<TagsCheckboxGridProps> = (props) => {
  return (
    <section>
      <div
        data-testid="idea-card-grid"
        className={`idea-card-grid-container columns-${props.columns}`}
      >
        {props.ideas.map((idea) => (
          <IdeaCardMolecules
            hover_effect={!props.isSP}
            idea={idea}
            text_size={props.card_text_size}
            width={'auto'}
          />
        ))}
      </div>
      {props.page && props.max_page && (
        <PageNationButtons num={props.page} max_num={props.max_page} />
      )}
    </section>
  );
};

export default IdeaCardGrid;
