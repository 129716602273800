import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  DUMMY_TAGS,
  DUMMY_USER_CARD_DATA,
  MULTI_DUMMY_IDEA_CARD_DATA,
} from '../../constants/dummy';
import { RootState } from '../../redux/store';
import { UserCardType } from '../../types/api/user/UserTypes';
import {
  getSearchPageTitle,
  parseSearchSortQuery,
} from '../../types/query/searchQuery';
import SearchTemplatePC from '../templates/search/SearchTemplatePC';
import SearchTemplateSP from '../templates/search/SearchTemplateSP';

const SearchPage = () => {
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1', 10);

  // 注目のユーザー
  const user1: UserCardType = DUMMY_USER_CARD_DATA;
  const user2: UserCardType = DUMMY_USER_CARD_DATA;
  const user3: UserCardType = DUMMY_USER_CARD_DATA;

  // ソート
  const sort = parseSearchSortQuery(searchParams.get('sort'));

  // ユーザーID
  const userId = searchParams.get('user_id');
  const user: UserCardType = DUMMY_USER_CARD_DATA;

  // カテゴリID
  const categoryId = searchParams.get('category_id');

  // キーワード
  const keyword = searchParams.get('keyword');
  const ideas = MULTI_DUMMY_IDEA_CARD_DATA;

  const props = {
    title: sort
      ? getSearchPageTitle(sort) + 'アイデア'
      : keyword
        ? keyword + ' の検索結果 (' + ideas.length + '件)'
        : userId
          ? user.user_name + ' のアイデア'
          : categoryId
            ? DUMMY_TAGS.find((tag) => tag.tag_id === categoryId)?.name +
              ' のアイデア'
            : '',
    page: page,
    max_page: 10,
    ideas: ideas,
    users: [user1, user2, user3],
  };

  return isXS ? (
    <SearchTemplateSP {...props} />
  ) : (
    <SearchTemplatePC
      {...props}
      size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
    />
  );
};

export default SearchPage;
