import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserCardType } from '../../types/api/user/UserTypes';

const INIT_USER_CARD_DATA: UserCardType = {
  user_id: '',
  user_name: '',
  img_src: '',
  description: '',
  type: '',
};
const initialState: UserCardType = INIT_USER_CARD_DATA;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<UserCardType>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { loginUser: loginUser } = userSlice.actions;
export default userSlice.reducer;
