import React from 'react';
import { useDispatch } from 'react-redux';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { openIdeaPostPopup } from '../../../redux/slice/ideaPostPopupSlice';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { ButtonSizeType } from '../../atoms/button/Button.type';
import { TextSizeType } from '../../atoms/text/Text.type';
import CircleFixedPostButtonIons from '../../ions/button/circle/pink/CircleFixedPostButton.Ions';
import HomeTitleAndSubtitle from '../../molecules/text/title/HomeTitleAndSubtitle';
import IdeaCarousel from '../../organisms/carousel/IdeaCarousel';
import TopBannerCarousel from '../../organisms/carousel/TopBannerCarousel';
import './HomeTemplate.css';

interface HomeTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  top_ideas: IdeaCardType[];
  main_ideas1: IdeaCardType[];
  main_ideas2: IdeaCardType[];
  main_ideas3: IdeaCardType[];
}

const HomeTemplate: React.FC<HomeTemplateProps> = (props) => {
  const dispatch = useDispatch();
  const { goToSearchPage } = usePageNavigation();
  const { size } = props;

  let slide_min_width: number;
  let card_min_width: number;
  let main_width: string;
  let title_size: TextSizeType;
  let subTitle_size: TextSizeType;
  let button_size: ButtonSizeType;

  switch (size) {
    case 'sp':
      slide_min_width = window.innerWidth * 1.0;
      card_min_width = 200;
      main_width = '95%';
      title_size = 'bit-large';
      subTitle_size = 'normal';
      button_size = 'medium';
      break;

    case 'small':
      slide_min_width = 600;
      card_min_width = 240;
      main_width = '95%';
      title_size = 'very-large';
      subTitle_size = 'medium';
      button_size = 'large';
      break;

    case 'medium':
      slide_min_width = 800;
      card_min_width = 300;
      main_width = '92%';
      title_size = 'very-large';
      subTitle_size = 'large';
      button_size = 'large';
      break;

    case 'large':
      slide_min_width = 1000;
      card_min_width = 320;
      main_width = '85%';
      title_size = 'extra-large';
      subTitle_size = 'large';
      button_size = 'extra-large';
      break;

    default:
      slide_min_width = window.innerWidth * 0.6;
      card_min_width = 240;
      main_width = '85%';
      title_size = 'large';
      subTitle_size = 'large';
      button_size = 'extra-large';
  }

  if (size === 'none') {
    return null;
  }

  return (
    <div className="home-template">
      <TopBannerCarousel
        banner_img_src={[
          '/images/static/REIDEA-BANNER.jpg',
          '/images/static/REIDEA-BANNER2.jpg',
          '/images/static/REIDEA-BANNER3.jpg',
        ]}
        slide_min_width={slide_min_width}
        isBanner={size === 'sp'}
      />

      <main className="home-template-main">
        <IdeaCarousel
          isSP={size === 'sp'}
          title="みんなのアイデア"
          subTitle="いいね と 出資 で実現させよう"
          type="title_with_bg"
          title_size={title_size}
          subTitle_size={subTitle_size}
          slide_min_width={card_min_width * 1.05}
          card_width={card_min_width}
          ideas={props.top_ideas}
          button_size={button_size}
        />

        <section
          className="home-template-main-bottom"
          style={{ width: main_width }}
        >
          <HomeTitleAndSubtitle
            title="みんなのアイデア"
            subTitle="いいね と 出資 で実現させよう！"
            title_size={title_size}
            subTitle_size={subTitle_size}
          />
          <section className="home-template-main-carousels">
            <IdeaCarousel
              isSP={size === 'sp'}
              title="新しいアイデア"
              type="more"
              title_size={subTitle_size}
              slide_min_width={card_min_width * 1.05}
              card_width={card_min_width}
              ideas={props.main_ideas1}
              moreOnClick={() => goToSearchPage({ sort: 'new' })}
            />

            <IdeaCarousel
              isSP={size === 'sp'}
              title="人気のアイデア"
              type="more"
              title_size={subTitle_size}
              slide_min_width={card_min_width * 1.05}
              card_width={card_min_width}
              ideas={props.main_ideas2}
              moreOnClick={() => goToSearchPage({ sort: 'popular' })}
            />
            <IdeaCarousel
              isSP={size === 'sp'}
              title="おすすめのアイデア"
              type="more"
              title_size={subTitle_size}
              slide_min_width={card_min_width * 1.05}
              card_width={card_min_width}
              ideas={props.main_ideas3}
              moreOnClick={() => goToSearchPage({ sort: 'featured' })}
            />
          </section>
        </section>
      </main>

      <CircleFixedPostButtonIons
        onClick={() => {
          dispatch(openIdeaPostPopup());
        }}
        size={size === 'sp' ? 'medium' : 'large'}
        isMobile={size === 'sp'}
      />
    </div>
  );
};

export default HomeTemplate;
