import React from 'react';
import ImgAtoms from '../../atoms/img/Img.Atoms';
import { ImgAtomsProps } from '../../atoms/img/Img.type';
import './Img.Ions.css';

interface IdeaImgIonsProps extends ImgAtomsProps {
  onDelete?: () => void;
}

const IdeaImgIons: React.FC<IdeaImgIonsProps> = React.memo((props) => {
  const { onDelete, ...imgProps } = props;

  return (
    <div className="idea-img-ions-wrapper">
      <ImgAtoms {...imgProps} className={`idea-img-ions ${props.className}`} />
      {onDelete && (
        <button
          className="idea-img-ions-delete-button"
          onClick={onDelete}
          aria-label="Delete image"
        >
          ×
        </button>
      )}
    </div>
  );
});

export default IdeaImgIons;
