import React from 'react';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { RequestDetailType } from '../../../types/api/request/RequestTypes';
import { UserDetailType } from '../../../types/api/user/UserTypes';
import { ButtonSizeType } from '../../atoms/button/Button.type';
import { TextSizeType } from '../../atoms/text/Text.type';
import UserBannerImgIons from '../../ions/img/UserBannerImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import { HomeSubTitleTextIons } from '../../ions/text/subtitle/HomeSubTitle.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import IdeaCarousel from '../../organisms/carousel/IdeaCarousel';
import RequestCarousel from '../../organisms/carousel/RequestCarousel';
import './IntroduceTemplate.css';

interface IntroduceTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  user: UserDetailType;
  user_requests: RequestDetailType[];
  user_ideas: IdeaCardType[];
}

const IntroduceTemplateSP: React.FC<IntroduceTemplateProps> = (props) => {
  const { user, size } = props;

  let slide_min_width: number;
  let card_min_width: number;
  let main_width: string;
  let title_size: TextSizeType;
  let subTitle_size: TextSizeType;
  let button_size: ButtonSizeType;

  // レスポンシブ対応
  switch (size) {
    case 'sp':
      slide_min_width = window.innerWidth * 1.0;
      card_min_width = 200;
      main_width = '95%';
      title_size = 'bit-large';
      subTitle_size = 'normal';
      button_size = 'medium';
      break;

    case 'small':
      slide_min_width = 600;
      card_min_width = 240;
      main_width = '95%';
      title_size = 'very-large';
      subTitle_size = 'medium';
      button_size = 'large';
      break;

    case 'medium':
      slide_min_width = 800;
      card_min_width = 300;
      main_width = '92%';
      title_size = 'very-large';
      subTitle_size = 'large';
      button_size = 'large';
      break;

    case 'large':
      slide_min_width = 1000;
      card_min_width = 320;
      main_width = '85%';
      title_size = 'extra-large';
      subTitle_size = 'large';
      button_size = 'extra-large';
      break;

    default:
      slide_min_width = window.innerWidth * 0.6;
      card_min_width = 240;
      main_width = '85%';
      title_size = 'large';
      subTitle_size = 'large';
      button_size = 'extra-large';
  }

  if (size === 'none') {
    return null;
  }

  return (
    <div className="introduce-template sp">
      <header className="introduce-template-header-banner sp">
        <UserBannerImgIons img_src={user.banner_img_src || ''} />
      </header>
      <main className="introduce-template-main sp">
        <div className="introduce-template-main-cell">
          <HomeSubTitleTextIons text={user.user_name} size={subTitle_size} />
          <BodyTextIons text={user.description} size="normal" />
          <BodyTextIons
            text={'お題投稿数: ' + user.num_of_ideas + ' 件'}
            size="small"
          />
          <BodyTextIons
            text={`獲得した総閲覧数: ${user.num_of_total_pv} pv`}
            size="small"
          />
          <BodyTextIons
            text={'アイデア実現実績: ' + user.num_of_ideas + ' 件'}
            size="small"
          />
          <BodyTextIons
            text={'最近のログイン: ' + user.last_loginedAt + ''}
            size="small"
          />
        </div>

        <div className="introduce-template-main-cell">
          <BodyTextBoldIons
            text="お問い合わせのセル(SNSリンク)"
            size="normal"
          />
          <BodyTextIons
            text="会社HPとお問い合わせ情報をここに記載"
            size="small"
          />
        </div>

        <div className="introduce-template-main-cell">
          <BlackTitleTextIons
            text="募集中のお題(投稿したアイデア)"
            size={'normal'}
          />
          <RequestCarousel
            display_link={true}
            card_width={200}
            slide_min_width={220}
            requests={props.user_requests}
          />
        </div>

        <div className="introduce-template-main-cell">
          <BlackTitleTextIons text="いいねしたアイデア" size={'normal'} />
          <IdeaCarousel
            isSP
            title={null}
            isTwoRows={false}
            slide_min_width={180}
            card_width={170}
            ideas={props.user_ideas}
            button_size={button_size}
          />
        </div>

        <div className="introduce-template-main-cell">
          <BlackTitleTextIons text="実現したアイデア" size={'normal'} />
          <BodyTextIons text="アイデアの特集" size="normal" />
        </div>

        <div className="introduce-template-main-cell">
          <BodyTextBoldIons text="売っている商品とそのリンク1" size="small" />
          <BodyTextIons
            text="商品紹介とそのリンクボタンをここに記載"
            size="small"
          />
        </div>
        <div className="introduce-template-main-cell">
          <BodyTextBoldIons text="売っている商品とそのリンク1" size="small" />
          <BodyTextIons
            text="商品紹介とそのリンクボタンをここに記載"
            size="small"
          />
        </div>
        <div className="introduce-template-main-cell">
          <BodyTextBoldIons text="売っている商品とそのリンク1" size="small" />
          <BodyTextIons
            text="商品紹介とそのリンクボタンをここに記載"
            size="small"
          />
        </div>
      </main>
    </div>
  );
};

export default IntroduceTemplateSP;
