import React from 'react';
import { RequestDetailType } from '../../../types/api/request/RequestTypes';
import { PageNavigation } from '../../../utils/pageNavigation';
import DefaultCarouselIons from '../../ions/carousel/DefaultCarousel.Ions';
import RequestImgIons from '../../ions/img/RequestImg.Ions';
import { BodyTextIonsClickable } from '../../ions/text/body/BodyTextClickable.Ions';
import './IdeaCarousel.css';

interface RequestCarouselProps {
  slide_min_width: number;
  card_width: number;
  requests: RequestDetailType[];
  display_link?: boolean;
  setRequestID?: (value: string | null) => void;

  otherTransparent?: boolean;
  playOnInit?: boolean;
  isBanner?: boolean;
}

const RequestCarousel: React.FC<RequestCarouselProps> = (props) => {
  return (
    <section className="request-calousel-wrapper">
      <DefaultCarouselIons
        slide_min_width={props.slide_min_width}
        isTwoRows={false}
        setSelectedID={props.setRequestID}
        IDs={props.requests.map((request) => request.request_id)}
        items={[
          ...props.requests.map((request, index: number) => (
            <div
              className="request-carousel-item"
              onClick={() =>
                PageNavigation.goToRequestPage({ request_id: '1' })
              }
            >
              {index !== 0 && <RequestImgIons img_src={request.img_src} />}
              {index === 0 && <RequestImgIons img_src={request.img_src} />}
              {props.display_link && (
                <BodyTextIonsClickable
                  text={
                    index !== 0
                      ? '「' + request.title + '」の詳細ページへ'
                      : ' '
                  }
                  size="extra-small"
                />
              )}
            </div>
          )),
        ]}
        displayButton={true}
        playOnInit={props.playOnInit ?? true}
        otherTransparent={props.otherTransparent}
        isBanner={props.isBanner}
      />
    </section>
  );
};

export default RequestCarousel;
