export type IdeaPageViewQuery = 'home' | 'comment' | 'activity';

// 配列とタイトルを連携させるオブジェクト
export const ideaPageViewQueryMap = {
  home: 'ホーム',
  comment: 'コメント',
  activity: '活動報告',
};

// パース関数：無効な値の場合は 'home' を返す
export const parseIdeaViewQuery = (view?: string | null): IdeaPageViewQuery => {
  if (view && Object.keys(ideaPageViewQueryMap).includes(view)) {
    return view as IdeaPageViewQuery;
  } else {
    return 'home';
  }
};

// タイトル取得関数
export const getIdeaPageTitle = (query: IdeaPageViewQuery): string =>
  ideaPageViewQueryMap[query] || '不明';
