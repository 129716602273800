import { useSearchParams } from 'react-router-dom';

const useSetQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setPageQuery = (num: number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', num.toString());
    setSearchParams(newParams);
  };

  return { setPageQuery };
};

export default useSetQuery;
