import React from 'react';
import { TextSizeType } from '../../atoms/text/Text.type';
import UserIconImgIons from '../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { ChatTextIons } from '../../ions/text/message/ChatText.Ions';
import './Chat.Molecules.css';

type ChatProps = {
  left?: boolean;
  user_name: string;
  user_img_src: string;
  createdAt: string;
  size?: TextSizeType;
};

export const ChatMolecules: React.FC<ChatProps> = React.memo((props) => {
  return (
    <div
      className={`chat-molecules-container ${props.left ? 'left' : 'right'}`}
    >
      <UserIconImgIons
        user_name={props.user_name}
        img_src={props.user_img_src}
        size="small"
      />
      <div className={`chat-messages ${props.left ? 'left' : 'right'}`}>
        <ChatTextIons
          text={'チャットメッセージです'}
          size={props.size}
          left={props.left}
        />
        <div
          className={`chat-messages-read-time ${props.left ? 'left' : 'right'}`}
        >
          <BodyTextIons text={'既読'} rows={1} size="tiny" />
          <BodyTextIons text={props.createdAt} rows={1} size="tiny" />
        </div>
      </div>
    </div>
  );
});
