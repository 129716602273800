import { useSearchParams } from 'react-router-dom';
import { NAV_MYPAGE_OPTIONS } from '../../../constants/sorts';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import NavTextsMolecules from '../../molecules/text/nav/NavTexts.Molecules';

const MypageNavTexts = () => {
  const { goToMyPage } = usePageNavigation();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  return (
    <NavTextsMolecules
      textOptions={NAV_MYPAGE_OPTIONS}
      onClick={(value) => goToMyPage({ mode: value })}
      value={mode || ''}
    />
  );
};

export default MypageNavTexts;
