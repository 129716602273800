import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateScreenSize } from '../redux/slice/screenSizeSlice';
import { getScreenSize } from '../utils/getScreenSize';

const useResizeListener = () => {
  const dispatch = useDispatch();

  const handleResize = () => {
    const width = window.innerWidth;

    dispatch(updateScreenSize(getScreenSize(width)));
  };

  useEffect(() => {
    handleResize(); // 初期化
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);
};

export default useResizeListener;
