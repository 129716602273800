import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { TextSizeType } from '../../atoms/text/Text.type';
import DefaultImgIons from '../../ions/img/DefaultImg.Ions';
import { BodySubTextIons } from '../../ions/text/body/BodySubText.Ions';
import { WhiteTextBoldIons } from '../../ions/text/white/WhiteTextBold.Ions';
import './RequestHeader.css';

interface RequestHeaderProps {
  img_src: string;
  title: string;
  title_size: TextSizeType;
  sub_title_size: TextSizeType;
  button_padding: string;
  buttons_width: string;
  mode: string;
}

const RequestHeader: React.FC<RequestHeaderProps> = React.memo((props) => {
  const button_options: { label: string; value: string }[] = [
    { label: 'IDEA募集', value: 'detail' },
    { label: 'IDEA一覧', value: 'list' },
    { label: '採用IDEA', value: 'adopt' },
  ];
  const { goToRequestPage } = usePageNavigation();

  return (
    <div className="request-header-wrapper">
      <DefaultImgIons img_src={props.img_src} className="request-header-img" />
      <WhiteTextBoldIons
        text={props.title}
        size={props.title_size}
        className="request-header-title-overlay"
      />
      <WhiteTextBoldIons
        size={props.title_size}
        text={props.title}
        className="request-header-title"
      />

      <div
        className="request-header-buttons-wrapper"
        style={{ width: props.buttons_width }}
      >
        {button_options.map((button_option, index) => (
          <div
            className={`request-header-button ${props.mode === button_option.value ? 'selected' : 'not-selected'}`}
            style={{ padding: props.button_padding }}
            onClick={() => goToRequestPage({ mode: button_option.value })}
          >
            {props.mode === button_option.value ? (
              <WhiteTextBoldIons
                size={props.sub_title_size}
                text={button_option.label}
                className="request-header-button-text"
              />
            ) : (
              <BodySubTextIons
                size={props.sub_title_size}
                text={button_option.label}
                className="request-header-button-text"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
});

export default RequestHeader;
