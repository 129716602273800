import React from 'react';
import { RadioButtonAtomsProps } from '../../../atoms/radio/RadioButton.type';
import DefaultRadioButtonIons from '../../../ions/radio/DefaultRadioButton.Ions';
import './RadioButtonGrid.css';

interface RadioButtonGridProps {
  label?: string;
  options: RadioButtonAtomsProps[];
  columns: number;
}

const RadioButtonGrid: React.FC<RadioButtonGridProps> = React.memo(
  ({ label, options, columns }) => {
    return (
      <section className="radio-button-grid-container-wrapper">
        {label && <label className="checkbox-grid-label">{label}</label>}
        <div
          data-testid="radio-button-grid"
          className={`radio-button-grid-container columns-${columns}`}
        >
          {options.map((option, index) => (
            <DefaultRadioButtonIons key={index} {...option} />
          ))}
        </div>
      </section>
    );
  },
);

export default RadioButtonGrid;
