import { RefObject, useEffect } from 'react';

interface ScrollOptions {
  offset?: number;
  behavior?: ScrollBehavior;
}

export const useScrollIntoViewWithOffset = (
  ref: RefObject<HTMLElement | null>,
  shouldScroll: boolean,
  options: ScrollOptions = {},
  dep?: string,
) => {
  useEffect(() => {
    if (shouldScroll && ref.current) {
      const element = ref.current;
      const y =
        element.getBoundingClientRect().top +
        (window.scrollY || document.documentElement.scrollTop) +
        (options.offset || 0);

      window.scrollTo({
        top: y,
        behavior: options.behavior || 'smooth',
      });
    }
  }, [ref, shouldScroll, dep, options.offset, options.behavior]);
};
