import { usePageNavigation } from '../../../../../hooks/usePageNavigation';
import { IdeaDetailType } from '../../../../../types/api/idea/IdeaTypes';
import { ideaPageViewQueryMap } from '../../../../../types/query/ideaQuery';
import { PageNavigation } from '../../../../../utils/pageNavigation';
import RoundPurpleButtonIons from '../../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../../ions/button/round/White/RoundWhiteButton.Ions';
import SelectedTabButtonIons from '../../../../ions/button/selected/SelectedTabButton.Ions';
import IdeaImgIons from '../../../../ions/img/IdeaImg.Ions';
import DefaultProgressBarIons from '../../../../ions/progress/DefaultProgressBar.Ions';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../../ions/text/body/BodyTextBold.Ions';
import { BlackTitleTextIons } from '../../../../ions/text/title/BlackTitleText.Ions';
import SNSCircleButtons from '../../../../molecules/sns/circle/SNSCircleButtons';
import TextTags from '../../../../molecules/text/tags/TextTags';
import './IdeaDetailAndCfPC.css';

interface TagsCheckboxGridProps {
  width?: string | number;
  view: string;
  idea: IdeaDetailType;
  text?: string;
  onClick?: () => void;
  likeIdea: () => void;
}

const IdeaDetailAndCfPC: React.FC<TagsCheckboxGridProps> = (props) => {
  const { goToIdeaPage } = usePageNavigation();
  const { idea } = props;
  return (
    <section className="idea-detail-and-cf-wrapper-pc">
      <section style={{ width: props.width }} className="idea-wrapper-pc-top">
        <section className="idea-wrapper-pc-top-left">
          <IdeaImgIons img_src={idea.img_src} className="idea-img" />
          <BodyTextIons rows={2} text={idea.description} size="normal" />

          <TextTags tags={idea.tags} size="normal" />

          <SNSCircleButtons size="small" />
        </section>

        <section className="idea-wrapper-pc-top-right">
          <section className="idea-wrapper-pc-top-right-subtitle">
            <BlackTitleTextIons text={idea.title} size="large" />

            <BodyTextIons text={idea.subTitle} size="normal" />
          </section>

          <section className="idea-wrapper-pc-top-right-progress">
            <section className="idea-wrapper-pc-top-right-progress-info-backer">
              <BodyTextBoldIons
                text={`￥ ${idea.num_of_invests}`}
                size="medium"
              />
              <BodyTextIons
                text={`${idea.num_of_investors}人の出資者`}
                size="normal"
              />
            </section>

            <DefaultProgressBarIons progress={idea.progress} size="large" />

            <section className="idea-wrapper-pc-top-right-progress-info-days">
              <BodyTextIons text={`${idea.progress}% 達成済み`} size="normal" />
              <BodyTextIons text={`${idea.num_of_likes} PV`} size="normal" />
            </section>
          </section>

          <section className="idea-wrapper-pc-top-right-buttons">
            <RoundPurpleButtonIons size="large" onClick={props.onClick}>
              {props.text}
            </RoundPurpleButtonIons>
            <div className="idea-wrapper-pc-top-right-buttons-sub">
              <RoundWhiteButtonIons
                size="medium"
                width={'50%'}
                onClick={() => {
                  PageNavigation.goToIdeaPage(idea.idea_id, {
                    view: 'comment',
                  });
                }}
              >
                コメント
              </RoundWhiteButtonIons>
              <RoundWhiteButtonIons
                size="medium"
                width={'50%'}
                onClick={props.likeIdea}
              >
                いいね
              </RoundWhiteButtonIons>
            </div>
          </section>
        </section>
      </section>

      <section
        style={{ width: props.width }}
        className="idea-wrapper-pc-bottom-tabs"
      >
        {Object.entries(ideaPageViewQueryMap).map(([key, value]) => {
          return (
            <SelectedTabButtonIons
              selected={props.view === key}
              onClick={() => {
                goToIdeaPage(idea.idea_id, { view: key });
              }}
            >
              {value +
                ` ${key === 'comment' ? `(${idea.num_of_comments})` : key === 'activity' ? `(${idea.num_of_activities})` : ''}`}
            </SelectedTabButtonIons>
          );
        })}
      </section>
    </section>
  );
};

export default IdeaDetailAndCfPC;
