import { useCallback, useState } from 'react';

interface UseTextValuesProps {
  values: string[];
  handleChange: (newValue: string) => void;
  handleReset: () => void;
}

const useTextValues = (init?: string[]): UseTextValuesProps => {
  const [values, setValues] = useState<string[]>(init ?? []);

  const handleChange = useCallback(
    (newValue: string) => {
      if (values.includes(newValue)) {
        setValues((prevValues) =>
          prevValues.filter((v: string) => newValue !== v),
        );
      } else {
        setValues((prevValues) => [...prevValues, newValue]);
      }
    },
    [values],
  );

  const handleReset = useCallback(() => {
    setValues([]);
  }, []);

  return { values, handleChange, handleReset };
};

export default useTextValues;
