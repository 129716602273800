import React from 'react';
import ButtonAtoms from '../../../atoms/button/Button.Atoms';
import { ButtonAtomsProps } from '../../../atoms/button/Button.type';
import './SelectedPageButton.Ions.css';

interface ButtonProps extends ButtonAtomsProps {
  selected?: boolean;
}

const SelectedPageButtonIons: React.FC<ButtonProps> = React.memo((props) => {
  return (
    <ButtonAtoms
      onClick={props.onClick}
      disabled={props.disabled}
      className={`selected-page-button-ions ${props.selected ? 'selected' : ''} ${props.size ?? 'small'}`}
      size={props.size}
    >
      {props.children}
    </ButtonAtoms>
  );
});

export default SelectedPageButtonIons;
