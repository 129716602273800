import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchPageSortQuery } from '../types/query/searchQuery';

export const usePageNavigation = () => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // 特定のアイデア詳細ページに遷移
  const goToIdeaPage = useCallback(
    (idea_id: string, params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/ideas/' + idea_id);
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/ideas/${idea_id}${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  // 特定のアイデア編集ページに遷移
  const goToIdeaEditPage = useCallback((ideaId: string) => {
    navigate(`/ideas/${ideaId}/edit`);
  }, []);

  // 特定のアイデア出資ページに遷移
  const goToIdeaInvestPage = useCallback((ideaId: string) => {
    navigate(`/ideas/${ideaId}/invest`);
  }, []);

  // 特定のアイデア出資確認ページに遷移
  const goToIdeaInvestConfirmPage = useCallback((ideaId: string) => {
    navigate(`/ideas/${ideaId}/invest/confirm`);
  }, []);

  // ホームページに遷移
  const goToHomePage = useCallback(() => {
    navigate(`/`);
  }, []);

  // 検索ページに遷移
  const goToSearchPage = useCallback(
    (
      params?:
        | Record<'sort', SearchPageSortQuery>
        | Record<'keyword', string>
        | Record<'category_id', string>
        | Record<'user_id', string>,
    ) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/search');
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/search${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  const goToMyPage = useCallback(
    (params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/mypage');
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/mypage${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  const goToIntroPage = useCallback(
    (params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/introduce');
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/introduce${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  const goToRequestPage = useCallback(
    (params?: Record<string, string>) => {
      if (!params || Object.keys(params).length === 0) {
        navigate('/request');
        return;
      }

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.append(key, value);
      });

      const queryString = searchParams.toString();
      navigate(`/request${queryString ? `?${queryString}` : ''}`);
    },
    [navigate],
  );

  return {
    goBack,
    goToIdeaPage,
    goToHomePage,
    goToIdeaInvestPage,
    goToIdeaInvestConfirmPage,
    goToIdeaEditPage,
    goToSearchPage,
    goToMyPage,
    goToIntroPage,
    goToRequestPage,
  };
};
