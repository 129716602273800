import React from 'react';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BodySelectedTextIons } from '../../../ions/text/body/BodySelectedText.Ions';
import './NavTexts.Molecules.css';

interface NavTextsProps {
  textOptions: {
    label: string;
    value: string;
  }[];
  onClick: (value: string) => void;
  value: string;
  text_size?: TextSizeType;
}

const NavTextsMolecules: React.FC<NavTextsProps> = React.memo((props) => {
  return (
    <section className="nav-texts-molecules">
      {props.textOptions.map((textOption, index) => (
        <BodySelectedTextIons
          key={index}
          text={textOption.label}
          size={props.text_size ?? 'small'}
          selected={props.value === textOption.value}
          onClick={() => props.onClick(textOption.value)}
        />
      ))}
    </section>
  );
});

export default NavTextsMolecules;
