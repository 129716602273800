import React from 'react';
import CheckboxAtoms from '../../../atoms/checkbox/Checkbox.Atoms';
import { CheckboxAtomsProps } from '../../../atoms/checkbox/Checkbox.type';
import './CheckboxGrid.css';

interface CheckboxGridProps {
  label?: string;
  options: CheckboxAtomsProps[];
  columns: number;
  error?: string | null;
  max?: number;
  num_of_checked?: number;
}

const CheckboxGrid: React.FC<CheckboxGridProps> = React.memo(
  ({ label, options, columns, error = null, max, num_of_checked }) => {
    return (
      <section className="checkbox-grid-container-wrapper">
        {label && <label className="checkbox-grid-label">{label}</label>}
        <div
          data-testid="checkbox-grid"
          className={`checkbox-grid-container columns-${columns}`}
        >
          {options.map((option, index) => (
            <CheckboxAtoms key={index} {...option} />
          ))}
        </div>
        <section className="checkbox-grid-error-max-container">
          {error && <div className="checkbox-grid-error">{error}</div>}
          {max && (
            <div
              className={`checkbox-grid-counter ${(num_of_checked ?? 0) >= max ? 'max' : ''}`}
            >
              {num_of_checked} / {max}
            </div>
          )}
        </section>
      </section>
    );
  },
);

export default CheckboxGrid;
