import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { RequestDetailType } from '../../../types/api/request/RequestTypes';
import { TextSizeType } from '../../atoms/text/Text.type';
import RoundWhiteButtonIons from '../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodySubTextIons } from '../../ions/text/body/BodySubText.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import TextTags from '../../molecules/text/tags/TextTags';
import UserIconAndName from '../../molecules/user/UserIconAndName';
import './RequestDetail.css';

interface TagsCheckboxGridProps {
  isSP: boolean;
  width?: string | number;
  subTitle_size?: TextSizeType;
  title_size?: TextSizeType;
  request: RequestDetailType;
}

const RequestDetail: React.FC<TagsCheckboxGridProps> = (props) => {
  const { request } = props;
  const { goToIntroPage } = usePageNavigation();
  return (
    <div
      className={`request-detail-wrapper ${props.isSP ? 'sp' : ''}`}
      style={{
        width: props.width,
      }}
    >
      <div className={`request-detail-titles ${props.isSP ? 'sp' : ''}`}>
        <div className={`request-detail-cell ${props.isSP ? 'sp' : ''}`}>
          <BodySubTextIons size={props.subTitle_size} text="IDEA募集詳細" />
          <BlackTitleTextIons size={props.title_size} text={request.title} />
          <TextTags size={props.subTitle_size} tags={request.tags} />
        </div>
        <div className={`request-detail-cell ${props.isSP ? 'sp' : ''}`}>
          <BodySubTextIons size={props.subTitle_size} text="募集期限" />
          <BlackTitleTextIons size={props.title_size} text={request.deadline} />
        </div>
        <div className={`request-detail-cell ${props.isSP ? 'sp' : ''}`}>
          <BodySubTextIons size={props.subTitle_size} text="IDEA採用特典" />
          <BlackTitleTextIons
            size={props.title_size}
            text={request.reward_description}
          />
        </div>
      </div>

      <div className="request-detail-user">
        <BodySubTextIons text="お題の投稿者" size={props.subTitle_size} />
        <div className={`request-detail-user-cell ${props.isSP ? 'sp' : ''}`}>
          <UserIconAndName
            user_name={request.user_name}
            user_img_src={request.user_img_src}
            icon_size={props.isSP ? 'small' : 'medium'}
          />
          <BodyTextIons
            text={request.user_description}
            size={props.isSP ? 'extra-small' : 'small'}
          />
          <RoundWhiteButtonIons
            onClick={() => {
              goToIntroPage();
            }}
          >
            詳細を見る
          </RoundWhiteButtonIons>
        </div>
      </div>
    </div>
  );
};

export default RequestDetail;
