import { IdeaPageViewQuery } from '../types/query/ideaQuery';

export const PageNavigation = {
  // 前のページに戻る
  goBack: () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.href = '/'; // 履歴がない場合はホームページに遷移
    }
  },

  // 特定のアイデア詳細ページに遷移
  goToIdeaPage: (
    idea_id: string,
    params?: Record<'view', IdeaPageViewQuery>,
  ) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = `/ideas/${idea_id}`;
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/ideas/${idea_id}${queryString ? `?${queryString}` : ''}`;
  },

  // 特定のアイデア編集ページに遷移
  goToIdeaEditPage: (ideaId: string) => {
    window.location.href = `/ideas/${ideaId}/edit`;
  },

  // 特定のアイデア出資ページに遷移
  goToIdeaInvestPage: (ideaId: string) => {
    window.location.href = `/ideas/${ideaId}/invest`;
  },

  // ホームページに遷移
  goToHomePage: () => {
    window.location.href = `/`;
  },

  // 検索ページに遷移
  goToSearchPage: (params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/search';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/search${queryString ? `?${queryString}` : ''}`;
  },

  // マイページに遷移
  goToMyPage: (params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/mypage';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/mypage${queryString ? `?${queryString}` : ''}`;
  },

  // マイページに遷移
  goToIntroPage: (params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/introduce';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/introduce${queryString ? `?${queryString}` : ''}`;
  },

  // お題詳細ページに遷移
  goToRequestPage: (params?: Record<string, string>) => {
    if (!params || Object.keys(params).length === 0) {
      window.location.href = '/request';
      return;
    }

    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) searchParams.append(key, value);
    });

    const queryString = searchParams.toString();
    window.location.href = `/request${queryString ? `?${queryString}` : ''}`;
  },
};
