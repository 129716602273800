import React, { ChangeEvent } from 'react';
import { IDEA_STORY_MAX_LENGTH } from '../../../../constants/max';
import { TextFieldSizeType } from '../../../atoms/textfield/TextField.type';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';

interface ButtonProps {
  num?: number;
  require: boolean | null;
  story: string;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  size?: TextFieldSizeType;
}

const IdeaStoryTextfield: React.FC<ButtonProps> = React.memo((props) => {
  return (
    <DefaultTextFieldIons
      value={props.story}
      error={props.error}
      width={'100%'}
      size={props.size}
      onChange={props.handleChange}
      label={
        props.num ? (
          <NumAndTitle
            num={props.num}
            title="アイデアについて語ってください"
            num_size="small"
            title_size="small"
          />
        ) : props.require === null ? (
          <BodyTextBoldIons
            size="small"
            text="アイデアについて語ってください"
          />
        ) : (
          <RequireAndTitle
            require={true}
            title="アイデアについて語ってください"
          />
        )
      }
      placeholder="アイデアの詳細説明や、誕生ストーリーを入力してください"
      max={IDEA_STORY_MAX_LENGTH}
      rows={3}
    />
  );
});

export default IdeaStoryTextfield;
