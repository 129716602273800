import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/auth/authSlice';
import { PageNavigation } from '../../../utils/pageNavigation';
import { BodySelectedTextIons } from '../../ions/text/body/BodySelectedText.Ions';
import './UserPopover.css';

interface UserPopoverProps {
  isSP?: boolean;
}

const UserPopover: React.FC<UserPopoverProps> = React.memo((props) => {
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(logout());
    PageNavigation.goToHomePage();
  }, [dispatch]);

  return (
    <section className="user-popover-wrapper">
      <nav className="user-popover-wrapper-nav-texts">
        <BodySelectedTextIons
          text="投稿したアイデア"
          onClick={() => PageNavigation.goToMyPage({ mode: 'my' })}
        />
        <BodySelectedTextIons
          text="事業者とのチャット"
          onClick={() => PageNavigation.goToMyPage({ mode: 'chat' })}
        />
        <BodySelectedTextIons
          text="ユーザー情報"
          onClick={() => PageNavigation.goToMyPage({ mode: 'info' })}
        />
        <BodySelectedTextIons text="ログアウト" onClick={handleLogout} />
      </nav>
    </section>
  );
});

export default UserPopover;
