import React, { useCallback } from 'react';
import useTextValue from '../../../../hooks/useTextValue';
import SelectedWhiteRoundButtonIons from '../../../ions/button/selected/SelectedWhiteRoundButton.Ions';
import './IdeaDisplaySelectedButtons.css';

const IdeaDisplaySelectedButtons = React.memo(() => {
  const { value, handleChange } = useTextValue('display');

  // ボタンに渡す用に、空の関数へ変形している。
  const handleChangeDisplay = useCallback(() => {
    handleChange('display');
  }, []);
  const handleChangeNotDisplay = useCallback(() => {
    handleChange('not-display');
  }, []);

  return (
    <section className="idea-display-selected-buttons-wrapper">
      <SelectedWhiteRoundButtonIons
        onClick={handleChangeDisplay}
        size="small"
        selected={value === 'display'}
      >
        公開
      </SelectedWhiteRoundButtonIons>
      <SelectedWhiteRoundButtonIons
        selected={value === 'not-display'}
        size="small"
        onClick={handleChangeNotDisplay}
      >
        非公開
      </SelectedWhiteRoundButtonIons>
    </section>
  );
});

export default IdeaDisplaySelectedButtons;
