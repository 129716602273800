import React, { useRef } from 'react';
import useOpen from '../../../hooks/useOpen';
import { useScrollIntoViewWithOffset } from '../../../hooks/useScrollIntoViewWithOffset';
import IdeaCarousel from '../../organisms/carousel/IdeaCarousel';
import IdeaFooter from '../../organisms/footer/IdeaFooter';
import IdeaDetailAndCfSP from '../../organisms/idea/DetailAndCF/SP/IdeaDetailAndCfSP';
import IdeaUserAndReturns from '../../organisms/idea/useAndReturns/IdeaUserAndReturns';
import RewardConfirmPopup from '../../organisms/popup/RewardConfirmPopup';
import IdeaActivityTemplate from './acitivity/IdeaActivityTemplate';
import IdeaCommentTemplate from './comment/IdeaCommentTemplate';
import './IdeaTemplate.css';
import { IdeaTemplateProps } from './IdeaTemplate.type';
import IdeaStoryTemplate from './story/IdeaStoryTemplate';

const IdeaTemplateSP: React.FC<IdeaTemplateProps> = (props) => {
  const { idea } = props;
  const { open, handleClose, handleOpen } = useOpen();
  const bottomRef = useRef<HTMLElement>(null);
  useScrollIntoViewWithOffset(
    bottomRef,
    props.view === 'comment' || props.view === 'activity',
    {
      offset: -140,
    },
    props.view,
  );

  const width = '95%';

  return (
    <div className={`idea-template`}>
      <main className={`idea-template-main`}>
        <section className={`idea-template-top`}>
          <IdeaDetailAndCfSP view={props.view} idea={idea} width={width} />
        </section>

        <section
          style={{ width: width }}
          className={`idea-template-bottom sp`}
          ref={bottomRef}
        >
          {props.view === 'home' ? (
            <IdeaStoryTemplate idea={idea} title_size="small" />
          ) : props.view === 'comment' ? (
            <IdeaCommentTemplate
              idea={idea}
              idea_comments={props.idea_comments}
              title_size="normal"
              sendIdeaComment={props.sendIdeaComment}
            />
          ) : (
            <IdeaActivityTemplate
              idea={idea}
              idea_activities={props.idea_activities}
              title_size="small"
              sendIdeaActivity={props.sendIdeaActivity}
            />
          )}
          <IdeaUserAndReturns idea={idea} title_size="small" />
        </section>
        <IdeaCarousel
          isSP
          type="title_with_bg"
          title="おすすめのアイデア"
          title_size="bit-large"
          subTitle="いいね と 出資 で実現させよう"
          subTitle_size="normal"
          slide_min_width={228}
          card_width={220}
          ideas={props.suggest_ideas}
          button_size="medium"
        />
      </main>

      <IdeaFooter
        text="詳しく聞く"
        likeIdea={props.likeIdea}
        onClick={handleOpen}
        num_of_likes={idea.num_of_likes}
      />
      <RewardConfirmPopup open={open} onClose={handleClose} />
    </div>
  );
};

export default IdeaTemplateSP;
