import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { SelectedTabTextIons } from '../../ions/text/tab/SelectedTabText.Ions';
import './MypageNavTabs.css';

interface MypageNavTabsProps {
  mode?: string;
}

const MypageNavTabs: React.FC<MypageNavTabsProps> = (props) => {
  const { goToMyPage } = usePageNavigation();
  return (
    <nav className="mypage-nav-tabs">
      <SelectedTabTextIons
        text="あなたのアイデア"
        selected={props.mode === 'my'}
        onClick={() => goToMyPage({ mode: 'my' })}
      />
      <SelectedTabTextIons
        text="メッセージ"
        selected={props.mode === 'chat'}
        onClick={() => goToMyPage({ mode: 'chat' })}
      />
      <SelectedTabTextIons
        text="ユーザー情報"
        selected={props.mode === 'info'}
        onClick={() => goToMyPage({ mode: 'info' })}
      />
    </nav>
  );
};

export default MypageNavTabs;
