import { likeIdeaProps } from './ideaLikeAPI.type';

// アイデアのコメントを送信するAPI
export const likeIdea = async (input: likeIdeaProps) => {
  const response = await fetch(`/api/ideas/${input.idea_id}/likes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(input),
  });

  if (!response.ok) {
    // throw new Error(`Failed to send comment: ${response.statusText}`);
    alert('Input:\n' + String(JSON.stringify(input)));
  }

  return response.json();
};
