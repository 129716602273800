import React, { useRef } from 'react';
import useOpen from '../../../hooks/useOpen';
import { useScrollIntoViewWithOffset } from '../../../hooks/useScrollIntoViewWithOffset';
import IdeaCarousel from '../../organisms/carousel/IdeaCarousel';
import IdeaDetailAndCfPC from '../../organisms/idea/DetailAndCF/PC/IdeaDetailAndCfPC';
import IdeaUserAndReturns from '../../organisms/idea/useAndReturns/IdeaUserAndReturns';
import RewardConfirmPopup from '../../organisms/popup/RewardConfirmPopup';
import IdeaActivityTemplate from './acitivity/IdeaActivityTemplate';
import IdeaCommentTemplate from './comment/IdeaCommentTemplate';
import './IdeaTemplate.css';
import { IdeaTemplateProps } from './IdeaTemplate.type';
import IdeaStoryTemplate from './story/IdeaStoryTemplate';

interface IdeaTemplatePCProps extends IdeaTemplateProps {
  size?: 'small' | 'medium' | 'large' | 'none';
}

const IdeaTemplatePC: React.FC<IdeaTemplatePCProps> = (props) => {
  const { idea } = props;
  const { open, handleClose, handleOpen } = useOpen();

  const bottomLeftRef = useRef<HTMLElement>(null);

  useScrollIntoViewWithOffset(
    bottomLeftRef,
    props.view === 'comment' || props.view === 'activity',
    {
      offset: -160,
    },
    props.view,
  );

  let width: string;
  switch (props.size) {
    case 'small':
      width = '95%';
      break;
    case 'medium':
      width = '850px';
      break;
    case 'large':
      width = '960px';
      break;
    default:
      width = '950px';
  }

  if (props.size === 'none') {
    return null;
  }

  return (
    <div className={`idea-template`}>
      <main className={`idea-template-main`}>
        <section className={`idea-template-top`}>
          <IdeaDetailAndCfPC
            view={props.view}
            idea={idea}
            width={width}
            text="詳しく聞く"
            onClick={handleOpen}
            likeIdea={props.likeIdea}
          />
        </section>

        <section style={{ width: width }} className={`idea-template-bottom`}>
          <section className={`idea-template-bottom-left`} ref={bottomLeftRef}>
            {props.view === 'home' ? (
              <IdeaStoryTemplate idea={idea} title_size="normal" />
            ) : props.view === 'comment' ? (
              <IdeaCommentTemplate
                idea={idea}
                idea_comments={props.idea_comments}
                title_size="normal"
                sendIdeaComment={props.sendIdeaComment}
              />
            ) : (
              <IdeaActivityTemplate
                idea={idea}
                idea_activities={props.idea_activities}
                title_size="normal"
                sendIdeaActivity={props.sendIdeaActivity}
              />
            )}
          </section>

          <section className={`idea-template-bottom-right `}>
            <IdeaUserAndReturns idea={idea} title_size="normal" />
          </section>
        </section>
        <IdeaCarousel
          isSP={false}
          title="おすすめのアイデア"
          subTitle="いいね と 出資 で実現させよう"
          slide_min_width={360}
          card_width={340}
          ideas={props.suggest_ideas}
          button_size="large"
          type="title_with_bg"
        />
      </main>

      <RewardConfirmPopup open={open} onClose={handleClose} />
    </div>
  );
};

export default IdeaTemplatePC;
