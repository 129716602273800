import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../../atoms/text/Text.type';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import { ReturnCardMolecules } from '../../../molecules/card/return/ReturnCard.Molecules';
import UserIconAndName from '../../../molecules/user/UserIconAndName';
import './IdeaUserAndReturns.css';

interface TagsCheckboxGridProps {
  idea: IdeaDetailType;
  title_size?: TextSizeType;
}

const IdeaUserAndReturns: React.FC<TagsCheckboxGridProps> = (props) => {
  const { idea } = props;
  const { goToIntroPage } = usePageNavigation();
  return (
    <section className="idea-user-and-returns-wrapper">
      <div className="idea-user-and-returns-cell">
        <BlackTitleTextIons text="アイデアの投稿者" size={props.title_size} />
        <div className="idea-user-cell">
          <UserIconAndName
            user_name={idea.user_name}
            user_img_src={idea.user_img_src}
            icon_size="medium"
          />
          <BodyTextIons text={idea.user_description} size="small" />
          <RoundWhiteButtonIons
            onClick={() => {
              goToIntroPage();
            }}
            size="medium"
          >
            詳細を見る
          </RoundWhiteButtonIons>
        </div>
      </div>

      <div className="idea-user-and-returns-cell">
        <BlackTitleTextIons text="リターン" size={props.title_size} />
        <ReturnCardMolecules stage={1} idea_id={props.idea.idea_id} />
        <ReturnCardMolecules stage={2} idea_id={props.idea.idea_id} />
        <ReturnCardMolecules stage={3} idea_id={props.idea.idea_id} />
      </div>
    </section>
  );
};

export default IdeaUserAndReturns;
