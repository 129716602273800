import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  DUMMY_ROOM_CARD_DATA,
  DUMMY_USER_DETAIL_DATA,
  MULTI_DUMMY_CHAT_DETAIL_DATA,
  MULTI_DUMMY_IDEA_CARD_DATA,
  MULTI_DUMMY_ROOM_CARD_DATA,
} from '../../constants/dummy';
import { getNavMypageLabel } from '../../constants/sorts';
import { RootState } from '../../redux/store';
import { ChatDetailType } from '../../types/api/chat/ChatTypes';
import { IdeaCardType } from '../../types/api/idea/IdeaTypes';
import { RoomCardType } from '../../types/api/room/RoomTypes';
import { UserDetailType } from '../../types/api/user/UserTypes';
import MyPageTemplatePC from '../templates/mypage/MyPageTemplatePC';
import MyPageTemplateSP from '../templates/mypage/MyPageTemplateSP';

const MyPage = () => {
  const [searchParams] = useSearchParams();
  const mode: string = searchParams.get('mode') ?? 'post';
  const chat_user_id: string | null = searchParams.get('chat_user_id') ?? null;
  const page = parseInt(searchParams.get('page') || '1', 10);
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const user: UserDetailType = DUMMY_USER_DETAIL_DATA;
  const user_ideas: IdeaCardType[] = MULTI_DUMMY_IDEA_CARD_DATA;
  const rooms: RoomCardType[] = MULTI_DUMMY_ROOM_CARD_DATA;
  const chat_room: RoomCardType = DUMMY_ROOM_CARD_DATA;
  const chats: ChatDetailType[] = MULTI_DUMMY_CHAT_DETAIL_DATA;

  return isXS ? (
    <MyPageTemplateSP
      mode={mode}
      title={getNavMypageLabel(mode)}
      chat_user_id={chat_user_id}
      page={page}
      max_page={10}
      user={user}
      user_ideas={user_ideas}
      rooms={rooms}
      chat_room={chat_room}
      chats={chats}
    />
  ) : (
    <MyPageTemplatePC
      mode={mode}
      title={getNavMypageLabel(mode)}
      size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
      chat_user_id={chat_user_id}
      page={page}
      max_page={10}
      user={user}
      user_ideas={user_ideas}
      rooms={rooms}
      chat_room={chat_room}
      chats={chats}
    />
  );
};

export default MyPage;
