import React from 'react';
import { sendIdeaActivityProps } from '../../../api/idea/activity/ideaActivityAPI.type';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import useTextfield from '../../../hooks/useTextfield.hooks';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../ions/popup/DefaultPopup.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import DefaultTextFieldIons from '../../ions/textfield/DefaultTextField.Ions';
import './IdeaActivityPostPopup.css';

interface PopupProps {
  idea_id: string;
  open: boolean;
  onClose: () => void;
  sendIdeaActivity: (input: sendIdeaActivityProps) => void;
}

const IdeaActivityPostPopup: React.FC<PopupProps> = React.memo(
  ({ idea_id, open, onClose, sendIdeaActivity }) => {
    const { goToIdeaPage } = usePageNavigation();
    const { value: title, handleChange: handleChangeTitle } = useTextfield();
    const { value: message, handleChange: handleChangeMessage } =
      useTextfield();

    return (
      <DefaultPopupIons open={open} onClose={onClose} size="large">
        <div className="reward-confirm-popup">
          <BodyTextBoldIons size="normal" text="活動を投稿" />

          <section className="reward-confirm-popup-rewards">
            <DefaultTextFieldIons
              width={'100%'}
              label="タイトル"
              placeholder="タイトルを入力してください"
              rows={1}
              value={title}
              error={null}
              onChange={handleChangeTitle}
            />
            <DefaultTextFieldIons
              width={'100%'}
              label="活動記録"
              placeholder="活動記録を入力してください"
              rows={4}
              value={message}
              error={null}
              onChange={handleChangeMessage}
            />
          </section>

          <RoundPurpleButtonIons
            width={'60%'}
            size="medium"
            onClick={() => {
              sendIdeaActivity({
                idea_id: idea_id,
                title: title,
                message: message,
              });
              onClose();
              goToIdeaPage(idea_id, { view: 'activity' });
            }}
          >
            投稿する
          </RoundPurpleButtonIons>
        </div>
      </DefaultPopupIons>
    );
  },
);

export default IdeaActivityPostPopup;
