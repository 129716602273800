import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import React from 'react';
import useSetQuery from '../../../hooks/useQuery';
import SelectedPageButtonIons from '../../ions/button/page/SelectedPageButton.Ions';
import './PageNationButtons.css';

interface PageNationButtonsProps {
  num: number;
  max_num: number;
}

const PageNationButtons: React.FC<PageNationButtonsProps> = React.memo(
  (props) => {
    const { setPageQuery } = useSetQuery();
    if (props.num < 1 || props.max_num < props.num) {
      return null;
    }
    return (
      <section className="page-nation-buttons-container">
        {props.num > 0 && props.num <= props.max_num && (
          <>
            {props.num !== 1 && (
              <>
                <SelectedPageButtonIons onClick={() => setPageQuery(1)}>
                  <KeyboardDoubleArrowLeftIcon
                    sx={{ color: 'grey', fontSize: 20 }}
                  />
                </SelectedPageButtonIons>
                <SelectedPageButtonIons
                  onClick={() => setPageQuery(props.num - 1)}
                >
                  <ArrowBackIosNewIcon sx={{ color: 'grey', fontSize: 16 }} />
                </SelectedPageButtonIons>
                <SelectedPageButtonIons
                  onClick={() => setPageQuery(props.num - 1)}
                >
                  {props.num - 1}
                </SelectedPageButtonIons>
              </>
            )}
            <SelectedPageButtonIons selected>
              {props.num}
            </SelectedPageButtonIons>
            {props.num !== props.max_num && (
              <>
                <SelectedPageButtonIons
                  onClick={() => setPageQuery(props.num + 1)}
                >
                  {props.num + 1}
                </SelectedPageButtonIons>
                <SelectedPageButtonIons
                  onClick={() => setPageQuery(props.num + 1)}
                >
                  <ArrowForwardIosIcon sx={{ color: 'grey', fontSize: 16 }} />
                </SelectedPageButtonIons>
                <SelectedPageButtonIons
                  onClick={() => setPageQuery(props.max_num)}
                >
                  <KeyboardDoubleArrowRightIcon
                    sx={{ color: 'grey', fontSize: 20 }}
                  />
                </SelectedPageButtonIons>
              </>
            )}
          </>
        )}
      </section>
    );
  },
);

export default PageNationButtons;
