import React from 'react';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { TagTextIons } from '../../../ions/text/tag/TagText.Ions';
import './TextTags.css';

interface HomeTitleAndSubtitleProps {
  tags: string[];
  size?: TextSizeType;
}

const TextTags: React.FC<HomeTitleAndSubtitleProps> = React.memo((props) => {
  const { goToSearchPage } = usePageNavigation();

  return (
    <section className="text-tags-molecules">
      {props.tags.map((tag: string) => (
        <TagTextIons
          key={tag}
          text={tag}
          size={props.size}
          onClick={goToSearchPage}
        />
      ))}
    </section>
  );
});

export default TextTags;
