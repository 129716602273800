import React from 'react';
import { IDEA_TAGS_MAX_LENGTH } from '../../../constants/max';
import { IdeaDetailType } from '../../../types/api/idea/IdeaTypes';
import RoundWhiteButtonIons from '../../ions/button/round/White/RoundWhiteButton.Ions';
import { CircleNumTextIons } from '../../ions/text/circle/CircleNumText.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import IdeaDisplaySelectedButtons from '../../organisms/button/selected/IdeaDisplaySelectedButtons';
import TagsCheckboxGrid from '../../organisms/grid/tagCheckboxGrid/TagsCheckboxGrid';
import IdeaImageGeneForm from '../../organisms/textfield/form/IdeaImageGene/IdeaImageGeneForm';
import IdeaStoryTextfield from '../../organisms/textfield/story/IdeaStoryTextfield';
import IdeaApealPointTextfield from '../../organisms/textfield/title/IdeaApealPointTextfield';
import IdeaSubTitleTextfield from '../../organisms/textfield/title/IdeaSubTitleTextfield';
import IdeaTitleTextfield from '../../organisms/textfield/title/IdeaTitleTextfield';
import './IdeaEditTemplate.css';
import { useIdeaEditForm } from './useIdeaEditForm.hooks';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaEditTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const { idea, size } = props;

  // ラッピングされたフォーム値
  const { values, errors, handlers } = useIdeaEditForm({
    title: idea.title,
    subTitle: idea.subTitle,
    story: idea.description,
    tags: idea.tags,
    generate: true,
    img: idea.img_src,
    apealPoint1: idea.apealPoints[0],
    apealPoint2: idea.apealPoints[1],
    apealPoint3: idea.apealPoints[2],
  });

  let width: string;
  switch (size) {
    case 'sp':
      width = '95%';
      break;
    case 'small':
      width = '70%';
      break;
    case 'medium':
      width = '55%';
      break;
    case 'large':
      width = '45%';
      break;
    default:
      width = '45%';
  }

  return (
    <div className="idea-edit-template">
      <main className="idea-edit-template-main" style={{ width: width }}>
        <header
          className={`idea-edit-template-main-header ${size === 'sp' ? 'sp' : ''}`}
        >
          <BlackTitleTextIons text="アイデアを編集" size="large" />
          <div className="idea-edit-template-main-header-buttons">
            <RoundWhiteButtonIons>プレビュー</RoundWhiteButtonIons>
            <RoundWhiteButtonIons>削除する</RoundWhiteButtonIons>
            <RoundWhiteButtonIons>保存する</RoundWhiteButtonIons>
          </div>
        </header>

        <section className="idea-edit-template-main-details">
          <div className="idea-edit-template-main-columns">
            <CircleNumTextIons num={1} size="medium" />
            <BlackTitleTextIons text="公開詳細設定" size="normal" />
          </div>
          <div className="idea-edit-template-main-contents">
            <IdeaDisplaySelectedButtons />
          </div>
        </section>

        <section className="idea-edit-template-main-details">
          <div className="idea-edit-template-main-columns">
            <CircleNumTextIons num={2} size="medium" />
            <BlackTitleTextIons text="アイデア詳細設定" size="normal" />
          </div>
          <div className="idea-edit-template-main-contents">
            <IdeaTitleTextfield
              require
              title={values.title}
              error={errors.title}
              handleChange={handlers.handleTitleChange}
            />
            <IdeaSubTitleTextfield
              subtitle={values.subTitle}
              error={errors.subTitle}
              handleChange={handlers.handleSubTitleChange}
            />
            <IdeaStoryTextfield
              require
              story={values.story}
              error={errors.story}
              handleChange={handlers.handleStoryChange}
            />
            <TagsCheckboxGrid
              require
              columns={4}
              max={IDEA_TAGS_MAX_LENGTH}
              tags={values.tags}
              error={errors.tags}
              handleChange={handlers.handleTagsChange}
            />
            <IdeaImageGeneForm
              require={true}
              text_size={size === 'sp' ? 'extra-small' : 'small'}
              display_generate_form={true}
              generate={values.generate}
              handleChangeGenerate={handlers.handleChangeGenerate}
              img={values.img}
              handleChangeImg={handlers.handleChangeImg}
            />
            <div
              className={`idea-edit-template-main-contents-apealpoints ${size === 'sp' ? 'sp' : ''}`}
            >
              <IdeaApealPointTextfield
                index={1}
                apealpoint={values.apealPoint1}
                error={errors.apealPoint1}
                handleChange={handlers.handleApealPoint1Change}
              />

              <IdeaApealPointTextfield
                index={2}
                apealpoint={values.apealPoint2}
                error={errors.apealPoint2}
                handleChange={handlers.handleApealPoint2Change}
              />

              <IdeaApealPointTextfield
                index={3}
                apealpoint={values.apealPoint3}
                error={errors.apealPoint3}
                handleChange={handlers.handleApealPoint3Change}
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default IdeaEditTemplate;
