import React, { ChangeEvent } from 'react';
import { IDEA_SUBTITLE_MAX_LENGTH } from '../../../../constants/max';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';

interface IdeaSubTitleTextfieldProps {
  subtitle: string | null;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
}

const IdeaSubTitleTextfield: React.FC<IdeaSubTitleTextfieldProps> = React.memo(
  (props) => {
    return (
      <DefaultTextFieldIons
        value={props.subtitle || ''}
        error={props.error}
        onChange={props.handleChange}
        width={'100%'}
        label={<RequireAndTitle require={false} title="サブタイトル" />}
        placeholder="アイデアのサブタイトルを入力してください"
        max={IDEA_SUBTITLE_MAX_LENGTH}
      />
    );
  },
);

export default IdeaSubTitleTextfield;
