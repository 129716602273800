import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { UserCardType } from '../../../types/api/user/UserTypes';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import NavTextsMolecules from '../../molecules/text/nav/NavTexts.Molecules';
import './NavTexts.css';

interface UserTemplateProps {
  users: UserCardType[];
}

const UserNavTexts: React.FC<UserTemplateProps> = (props) => {
  const { goToSearchPage } = usePageNavigation();
  return (
    <section className="with-title-nav-texts">
      <BodyTextBoldIons text="注目のユーザー" size="small" />
      <NavTextsMolecules
        textOptions={
          props.users.map((user) => ({
            label: user.user_name,
            value: user.user_id,
          })) ?? []
        }
        onClick={(value) => goToSearchPage({ user_id: value })}
        value=""
      />
    </section>
  );
};

export default UserNavTexts;
