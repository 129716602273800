import { ChatDetailType } from '../types/api/chat/ChatTypes';
import {
  IdeaActivityType,
  IdeaCardType,
  IdeaCommentType,
  IdeaDetailType,
} from '../types/api/idea/IdeaTypes';
import { RequestDetailType } from '../types/api/request/RequestTypes';
import { RoomCardType } from '../types/api/room/RoomTypes';
import { TagType } from '../types/api/tags/TagsType';
import { UserCardType, UserDetailType } from './../types/api/user/UserTypes';

export const DUMMY_IDEA_CARD_DATA: IdeaCardType = {
  idea_id: '1',
  img_src: '/images/static/REIDEA-BANNER.jpg',
  user_name: 'ユーザー名',
  user_img_src: '',
  tags: ['タグ1', 'タグ2'],
  title:
    'タイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトルタイトル',
  description:
    '説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文説明文',
  progress: 30,
  num_of_pv: 20,
  num_of_likes: 10,
  num_of_investors: 5,
};

export const MULTI_DUMMY_IDEA_CARD_DATA: IdeaCardType[] = [
  DUMMY_IDEA_CARD_DATA,
  DUMMY_IDEA_CARD_DATA,
  DUMMY_IDEA_CARD_DATA,
  DUMMY_IDEA_CARD_DATA,
  DUMMY_IDEA_CARD_DATA,
  DUMMY_IDEA_CARD_DATA,
  DUMMY_IDEA_CARD_DATA,
  DUMMY_IDEA_CARD_DATA,
];

export const DUMMY_IDEA_DETAIL_DATA: IdeaDetailType = {
  idea_id: '1',
  img_src: '/images/static/REIDEA-BANNER.jpg',
  title: '革新的なエコバックデザイン',
  subTitle: '環境に優しい次世代のバッグ',
  description:
    'このエコバックは再利用可能な素材で作られており、持ち運びやすさとスタイリッシュさを兼ね備えています。',
  createdAt: '2025-01-01T10:00:00Z',
  progress: 75, // プロジェクト進捗状況
  num_of_pv: 1200, // 閲覧数
  num_of_likes: 300, // いいね数
  num_of_investors: 25, // 出資者数
  num_of_invests: 500000, // 出資額
  num_of_comments: 45, // コメント数
  num_of_activities: 12, // アクティビティ数
  apealPoints: [
    '環境に優しい素材を使用',
    'コンパクトに折りたためる設計',
    '100種類以上のカラーバリエーション',
  ],

  user_id: '1',
  user_name: '田中 太郎',
  user_img_src: '/images/static/REIDEA-BANNER.jpg',
  user_description:
    '持続可能なデザインに情熱を注ぐクリエイター。特にエコ商品に強い関心を持つ。',

  tags: ['エコ', 'デザイン', '持続可能性'],

  request_id: '1',
  request_img_src: '/images/static/REIDEA-BANNER.jpg',
  request_title: 'リクエストタイトル',
};

export const DUMMY_IDEA_COMMENT_DATA = {
  comment_id: 'c1',
  idea_id: '1',
  user_id: '1',
  user_name: '佐藤 一郎',
  user_img_src: '/images/static/REIDEA-BANNER.jpg',
  user_type: '一般ユーザー',
  createdAt: '2025-01-08T12:30:00Z',
  comment: 'このアイデアはとても面白いですね！ぜひ応援したいです。',
  num_of_likes: 15,
  replies: [
    {
      comment_id: 'c1-1',
      user_id: '2',
      idea_id: '1',
      user_name: '山田 花子',
      user_img_src: '/images/static/REIDEA-BANNER.jpg',
      user_type: 'クリエイター',
      createdAt: '2025-01-08T13:00:00Z',
      comment: 'ありがとうございます！応援していただけると嬉しいです！',
      num_of_likes: 10,
      replies: [],
    },
  ],
};

export const MULTI_DUMMY_IDEA_COMMENT_DATA: IdeaCommentType[] = [
  DUMMY_IDEA_COMMENT_DATA,
  DUMMY_IDEA_COMMENT_DATA,
  DUMMY_IDEA_COMMENT_DATA,
  DUMMY_IDEA_COMMENT_DATA,
  DUMMY_IDEA_COMMENT_DATA,
  DUMMY_IDEA_COMMENT_DATA,
  DUMMY_IDEA_COMMENT_DATA,
  DUMMY_IDEA_COMMENT_DATA,
];

export const DUMMY_IDEA_ACTIVITY_DATA: IdeaActivityType = {
  activity_id: '1',
  title: 'アイデア100いいね達成',
  user_img_src: '/images/static/REIDEA-BANNER.jpg',
  user_name: '佐藤 一郎',
  createdAt: '2025-01-08T10:00:00Z',
  message:
    'アイデアが100いいねを達成しました！\n応援ありがとうございます！n応援ありがとうございます！n応援ありがとうございます！n応援ありがとうございます！n応援ありがとうございます！',
};

export const MULTI_DUMMY_IDEA_ACTIVITY_DATA: IdeaActivityType[] = [
  DUMMY_IDEA_ACTIVITY_DATA,
  DUMMY_IDEA_ACTIVITY_DATA,
  DUMMY_IDEA_ACTIVITY_DATA,
];

export const DUMMY_REQUEST_DETAIL_DATA: RequestDetailType = {
  request_id: 'r1',
  img_src: '/images/static/REQUEST-SAMPLE.png',
  title: '次世代スマートフォンの新機能を考案して欲しい',
  tags: ['スマートフォン', 'イノベーション', '未来技術'],
  description:
    '次世代のスマートフォンに搭載するべき新機能のアイデアを募集しています。実用性やユーザーの利便性を重視した提案を期待しています。',
  deadline: '2025-02-15',
  reward_description: '採用された場合、10万円相当のギフトカードを贈呈します。',

  user_id: 'u1',
  user_name: '佐藤 一郎',
  user_img_src: '/images/static/REIDEA-BANNER.jpg',
  user_description:
    'テクノロジー企業で働くプロダクトマネージャー。イノベーションを追求しています。',
};
export const MULTI_DUMMY_REQUEST_DETAIL_DATA: RequestDetailType[] = [
  DUMMY_REQUEST_DETAIL_DATA,
  DUMMY_REQUEST_DETAIL_DATA,
  DUMMY_REQUEST_DETAIL_DATA,
  DUMMY_REQUEST_DETAIL_DATA,
  DUMMY_REQUEST_DETAIL_DATA,
];

export const DUMMY_USER_CARD_DATA: UserCardType = {
  user_id: '1',
  user_name: '佐藤 一郎',
  img_src: '/images/static/REIDEA-BANNER.jpg',
  description:
    '未来のアイデアを形にすることを目指しています。趣味は読書とランニング。',
  type: 'アイデアメーカー',
};

export const DUMMY_USER_DETAIL_DATA: UserDetailType = {
  user_id: '1',
  user_name: '佐藤 一郎',
  mail: 'sample@gmail.com',
  img_src: '/images/static/REIDEA-BANNER.jpg',
  banner_img_src: '/images/static/REIDEA-BANNER.jpg',
  description:
    '未来のアイデアを形にすることを目指しています。趣味は読書とランニング。',
  type: 'アイデアメーカー',
  sns_link: 'https://twitter.com/',
  tags: ['テクノロジー', 'イノベーション', '未来技術'],

  num_of_ideas: 15,
  num_of_requests: 5,
  last_loginedAt: '2025-01-08T14:25:00',
  num_of_realized_ideas: 3,
  num_of_total_pv: 12345,
  num_of_total_likes: 543,
  num_of_total_investors: 23,
  num_of_total_invests: 5000,
  num_of_total_comments: 200,
};

export const DUMMY_ROOM_CARD_DATA: RoomCardType = {
  room_id: 'r1',
  to_user_id: 'u1',
  to_user_name: '佐藤 一郎',
  to_user_img_src: '/images/static/REIDEA-BANNER.jpg',
  unread_count: 2,
  last_message: 'よろしくお願いいたします！',
  last_message_time: '2025-01-08T14:45:00',
};

export const MULTI_DUMMY_ROOM_CARD_DATA: RoomCardType[] = [
  DUMMY_ROOM_CARD_DATA,
  DUMMY_ROOM_CARD_DATA,
  DUMMY_ROOM_CARD_DATA,
  DUMMY_ROOM_CARD_DATA,
  DUMMY_ROOM_CARD_DATA,
  DUMMY_ROOM_CARD_DATA,
  DUMMY_ROOM_CARD_DATA,
];

export const DUMMY_CHAT_DETAIL_DATA: ChatDetailType = {
  room_id: 'r1',
  user_id: 'u1',
  user_name: '佐藤 一郎',
  user_img_src: '/images/static/REIDEA-BANNER.jpg',
  message: 'こんにちは！このアイデアについて詳しく教えていただけますか？',
  img_src: null,
  viewed: true,
  createdAt: '2025-01-08T14:30:00',
};

export const MULTI_DUMMY_CHAT_DETAIL_DATA: ChatDetailType[] = [
  DUMMY_CHAT_DETAIL_DATA,
  DUMMY_CHAT_DETAIL_DATA,
  DUMMY_CHAT_DETAIL_DATA,
  DUMMY_CHAT_DETAIL_DATA,
  DUMMY_CHAT_DETAIL_DATA,
  DUMMY_CHAT_DETAIL_DATA,
  DUMMY_CHAT_DETAIL_DATA,
];

export const DUMMY_TAGS: TagType[] = [
  { tag_id: '1', name: 'テクノロジー' },
  { tag_id: '2', name: 'デザイン' },
  { tag_id: '3', name: 'ビジネス' },
  { tag_id: '4', name: 'エンターテイメント' },
  { tag_id: '5', name: 'アート' },
  { tag_id: '6', name: 'スポーツ' },
  { tag_id: '7', name: '教育' },
  { tag_id: '8', name: '健康' },
  { tag_id: '9', name: '環境' },
  { tag_id: '10', name: 'その他' },
];
