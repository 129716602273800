import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PeopleIcon from '@mui/icons-material/People';
import React from 'react';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import { IdeaCardType } from '../../../../types/api/idea/IdeaTypes';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import IdeaImgIons from '../../../ions/img/IdeaImg.Ions';
import DefaultProgressBarIons from '../../../ions/progress/DefaultProgressBar.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { TagTextIons } from '../../../ions/text/tag/TagText.Ions';
import './IdeaCard.Molecules.css';

type IdeaCardProps = {
  idea: IdeaCardType;
  width: number | string;
  text_size?: 'extra-small' | 'small';
  hover_effect?: boolean;
  display_description?: boolean;
};

export const IdeaCardMolecules: React.FC<IdeaCardProps> = React.memo(
  (props) => {
    const { goToIdeaPage } = usePageNavigation();
    const icon_size = props.text_size === 'small' ? '20px' : '16px';

    return (
      <div
        className={`idea-card-molecules-container ${props.hover_effect ? 'hover-effect' : ''}`}
        style={{
          width: props.width,
        }}
        onClick={() => {
          goToIdeaPage(props.idea.idea_id);
        }}
      >
        <IdeaImgIons
          img_src={props.idea.img_src}
          alt={props.idea.title}
          className="idea-card-molecules-img"
        />
        <main
          className="idea-card-molecules-main"
          style={{
            gap: props.text_size === 'small' ? '5px' : '10px',
          }}
        >
          <header className="idea-card-molecules-header">
            <UserIconImgIons
              user_name={props.idea.user_name}
              img_src={props.idea.img_src}
              size={props.text_size}
            />
            <section className="idea-card-molecules-tags">
              {props.idea.tags.map((tag) => (
                <TagTextIons key={tag} text={tag} size={props.text_size} />
              ))}
            </section>
          </header>

          <section
            className="idea-card-molecules-texts"
            style={{
              gap: props.text_size === 'small' ? '10px' : '5px',
            }}
          >
            <BodyTextBoldIons
              rows={2}
              size={props.text_size}
              text={props.idea.title}
            />
            {props.display_description && props.idea.description && (
              <BodySubTextIons
                rows={3}
                size={props.text_size}
                text={props.idea.description}
              />
            )}
          </section>

          <section className="idea-card-molecules-progress">
            <DefaultProgressBarIons
              width={'70%'}
              progress={props.idea.progress}
              size="small"
            />
            <BodySubTextIons
              text={`${props.idea.num_of_pv}PV`}
              size={props.text_size}
            />
          </section>

          <section className="idea-card-molecules-progress">
            <div className="idea-card-molecules-cell">
              <FavoriteBorderIcon sx={{ color: 'grey', fontSize: icon_size }} />
              <BodySubTextIons
                text={props.idea.num_of_likes.toString()}
                size={props.text_size}
              />
            </div>
            <div className="idea-card-molecules-cell">
              <PeopleIcon sx={{ color: 'grey', fontSize: icon_size }} />
              <BodySubTextIons
                text={`出資${props.idea.num_of_investors}人`}
                size={props.text_size}
              />
            </div>
          </section>
        </main>
      </div>
    );
  },
);
