import React from 'react';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { CircleNumTextIons } from '../../../ions/text/circle/CircleNumText.Ions';
import './NumAndTitle.css';

interface NumAndTitleProps {
  num: number;
  title: string;
  num_size?: 'small' | 'normal' | 'large';
  title_size?: TextSizeType;
}

const NumAndTitle: React.FC<NumAndTitleProps> = React.memo((props) => {
  return (
    <section className="num-and-title-molecules">
      <CircleNumTextIons num={props.num} size={props.num_size ?? 'normal'} />
      <BodyTextBoldIons text={props.title} size={props.title_size ?? 'small'} />
    </section>
  );
});

export default NumAndTitle;
