import { usePageNavigation } from '../../../../../hooks/usePageNavigation';
import { IdeaDetailType } from '../../../../../types/api/idea/IdeaTypes';
import { ideaPageViewQueryMap } from '../../../../../types/query/ideaQuery';
import SelectedTabButtonIons from '../../../../ions/button/selected/SelectedTabButton.Ions';
import IdeaImgIons from '../../../../ions/img/IdeaImg.Ions';
import DefaultProgressBarIons from '../../../../ions/progress/DefaultProgressBar.Ions';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../../ions/text/body/BodyTextBold.Ions';
import { BlackTitleTextIons } from '../../../../ions/text/title/BlackTitleText.Ions';
import SNSCircleButtons from '../../../../molecules/sns/circle/SNSCircleButtons';
import TextTags from '../../../../molecules/text/tags/TextTags';
import './IdeaDetailAndCfSP.css';

interface TagsCheckboxGridProps {
  width?: string | number;
  view: string;
  idea: IdeaDetailType;
}

const IdeaDetailAndCfSP: React.FC<TagsCheckboxGridProps> = (props) => {
  const { idea } = props;
  const { goToIdeaPage } = usePageNavigation();
  return (
    <section className="idea-detail-and-cf-wrapper-sp">
      <section style={{ width: props.width }} className="idea-wrapper-sp-top">
        <IdeaImgIons img_src={idea.img_src} className="idea-img" />
        <BlackTitleTextIons text={idea.title} size="normal" />
        <BodyTextIons text={idea.subTitle} size="small" />
        <section className="idea-wrapper-sp-top-progress">
          <section className="idea-wrapper-sp-top-progress-info-backer">
            <BodyTextBoldIons
              text={`￥ ${idea.num_of_invests}`}
              size="normal"
            />
            <BodyTextIons
              text={`${idea.num_of_investors}人の出資者`}
              size="small"
            />
          </section>

          <DefaultProgressBarIons progress={30} size="large" />

          <section className="idea-wrapper-sp-top-right-progress-info-days">
            <BodyTextIons text={`${idea.progress}% 達成済み`} size="small" />
            <BodyTextIons text={`${idea.num_of_pv} PV`} size="small" />
          </section>
        </section>

        <TextTags tags={idea.tags} size="small" />

        <SNSCircleButtons size="small" />
      </section>

      <section
        style={{ width: props.width }}
        className="idea-wrapper-sp-bottom-tabs"
      >
        {Object.entries(ideaPageViewQueryMap).map(([key, value]) => {
          return (
            <SelectedTabButtonIons
              selected={props.view === key}
              onClick={() => {
                goToIdeaPage(idea.idea_id, { view: key });
              }}
            >
              {value +
                ` ${key === 'comment' ? `(${idea.num_of_comments})` : key === 'activity' ? `(${idea.num_of_activities})` : ''}`}
            </SelectedTabButtonIons>
          );
        })}
      </section>
    </section>
  );
};

export default IdeaDetailAndCfSP;
