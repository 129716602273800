import React from 'react';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { RequestDetailType } from '../../../types/api/request/RequestTypes';
import { TextSizeType } from '../../atoms/text/Text.type';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import RequestCarousel from '../../organisms/carousel/RequestCarousel';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import RequestDetail from '../../organisms/request/RequestDetail';
import RequestHeader from '../../organisms/requestHeader/RequestHeader';
import './RequestTemplate.css';

interface RequestTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  page?: number;
  max_page?: number;
  mode: string;

  request: RequestDetailType;
  ideas: IdeaCardType[];
  other_requests: RequestDetailType[];
}

const RequestTemplate: React.FC<RequestTemplateProps> = (props) => {
  const { request } = props;
  let header_height: number;
  let main_width: string;
  let title_size: TextSizeType;
  let subTitle_size: TextSizeType;
  let button_padding: string;
  let buttons_width: string;
  let columns: number;

  // レスポンシブ対応
  switch (props.size) {
    case 'sp':
      header_height = 230;
      main_width = '100%';
      title_size = 'small';
      subTitle_size = 'extra-small';
      button_padding = '2px 12px';
      buttons_width = '90%';
      columns = 2;
      break;

    case 'small':
      header_height = 350;
      main_width = '750px';
      title_size = 'medium';
      subTitle_size = 'small';
      button_padding = '8px 20px';
      buttons_width = '600px';
      columns = 3;
      break;

    case 'medium':
      header_height = 400;
      main_width = '900px';
      title_size = 'large';
      subTitle_size = 'normal';
      button_padding = '8px 30px';
      buttons_width = '740px';
      columns = 4;
      break;

    case 'large':
      header_height = 430;
      main_width = '1000px';
      title_size = 'large';
      subTitle_size = 'normal';
      button_padding = '8px 40px';
      buttons_width = '800px';
      columns = 4;
      break;

    default:
      header_height = 700;
      main_width = '65%';
      title_size = 'medium';
      subTitle_size = 'normal';
      button_padding = '8px 40px';
      buttons_width = '70%';
      columns = 4;
  }

  if (props.size === 'none') {
    return null;
  }

  return (
    <div className="request-template">
      <header
        className="request-template-header"
        style={{ width: main_width, height: header_height }}
      >
        <RequestHeader
          title_size={title_size}
          sub_title_size={subTitle_size}
          title={request.title}
          img_src={request.img_src}
          button_padding={button_padding}
          buttons_width={buttons_width}
          mode={props.mode}
        />
      </header>
      <main
        className={`request-template-main-container ${props.size === 'sp' ? 'sp' : ''}`}
      >
        <div className="request-template-main" style={{ width: main_width }}>
          {props.mode === 'detail' ? (
            <>
              <RequestDetail
                isSP={props.size === 'sp'}
                width={buttons_width}
                title_size={title_size}
                subTitle_size={props.size === 'sp' ? 'small' : 'normal'}
                request={request}
              />

              <div
                className="request-template-main-carousel"
                style={{ width: buttons_width }}
              >
                <BodyTextBoldIons
                  text="他のアイデア募集を見てみる"
                  size={subTitle_size}
                />
                <RequestCarousel
                  display_link={true}
                  card_width={props.size === 'sp' ? 260 : 350}
                  slide_min_width={props.size === 'sp' ? 270 : 350}
                  requests={props.other_requests}
                />
              </div>
            </>
          ) : props.mode === 'list' ? (
            <div
              className="request-template-main"
              style={{ width: buttons_width, alignItems: 'flex-start' }}
            >
              <IdeaCardGrid
                isSP={props.size === 'sp'}
                card_text_size="extra-small"
                columns={columns}
                ideas={props.ideas}
                page={props.page}
                max_page={props.max_page}
              />
            </div>
          ) : props.mode === 'adopt' ? (
            <></>
          ) : null}
        </div>
      </main>
    </div>
  );
};

export default RequestTemplate;
