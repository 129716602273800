import React from 'react';
import useTextValue from '../../../../hooks/useTextValue';
import { RadioButtonSizeType } from '../../../atoms/radio/RadioButton.type';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import RadioButtonGrid from '../../../molecules/radio/grid/RadioButtonGrid';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import './IdeaTypeButtonsGrid.css';

interface IdeaTypeButtonsGridProps {
  num?: number;
  size?: RadioButtonSizeType;
  text_size?: TextSizeType;
  columns?: number;
}

const IdeaTypeButtonsGrid: React.FC<IdeaTypeButtonsGridProps> = ({
  num,
  size,
  text_size,
  columns = 5,
}) => {
  const { value, handleChange } = useTextValue('1');
  type ValueType = { value: string; title: string };
  const values: ValueType[] = [
    {
      value: '1',
      title: '投稿したアイデア',
    },
    {
      value: '2',
      title: '出資したアイデア',
    },
    {
      value: '3',
      title: 'いいねしたアイデア',
    },
  ];

  return (
    <section className="idea-type-radio-button-wrapper">
      {num && (
        <NumAndTitle
          num={num}
          title="アイデアの画像を選択"
          num_size="small"
          title_size="small"
        />
      )}
      <div className={``}>
        <RadioButtonGrid
          columns={columns}
          options={values.map((v: ValueType) => ({
            checked: v.value === value,
            size: size,
            onChange: () => {
              handleChange(v.value);
            },
            children: (
              <div
                className={`idea-type-radio-button-cell ${v.value === value ? 'selected' : ''} ${size}`}
              >
                <BodyTextIons
                  text={v.title}
                  size={text_size}
                  onClick={() => {
                    handleChange(v.value);
                  }}
                />
              </div>
            ),
          }))}
        />
      </div>
    </section>
  );
};

export default IdeaTypeButtonsGrid;
