import React from 'react';
import { useSelector } from 'react-redux';
import { sendIdeaActivityProps } from '../../../../api/idea/activity/ideaActivityAPI.type';
import useOpen from '../../../../hooks/useOpen';
import { RootState } from '../../../../redux/store';
import {
  IdeaActivityType,
  IdeaDetailType,
} from '../../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../../atoms/text/Text.type';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import IdeaActivities from '../../../organisms/idea/activity/IdeaActivities';
import IdeaActivityPostPopup from '../../../organisms/popup/IdeaActivityPostPopup';
import './../IdeaTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  idea_activities: IdeaActivityType[];
  title_size?: TextSizeType;
  sendIdeaActivity: (input: sendIdeaActivityProps) => void;
}

const IdeaActivityTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const {
    open: activity_post_open,
    handleClose: handleCloseActivityPost,
    handleOpen: handleOpenActivityPost,
  } = useOpen();
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className="idea-activity-template">
      <div className="idea-activity-template-titles">
        <BlackTitleTextIons text="活動報告" size="small" />
        {props.idea.user_id === user.user_id && (
          <RoundWhiteButtonIons onClick={handleOpenActivityPost}>
            投稿する
          </RoundWhiteButtonIons>
        )}
      </div>

      <IdeaActivities idea_activities={props.idea_activities} />
      <IdeaActivityPostPopup
        open={activity_post_open}
        onClose={handleCloseActivityPost}
        idea_id={props.idea.idea_id}
        sendIdeaActivity={props.sendIdeaActivity}
      />
    </div>
  );
};

export default IdeaActivityTemplate;
