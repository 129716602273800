import React, { useRef } from 'react';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import IdeaImgIons from '../../ions/img/IdeaImg.Ions';
import { BodySubTextIons } from '../../ions/text/body/BodySubText.Ions';
import './ImageUploadForm.css';
import { useImageUpload } from './ImageUploadForm.hooks';

interface ImageUploadFormProps {
  img: string | File | null;
  onImageUpload: (file: File | null) => void;
}

// 画像がfileかstringで渡されることを想定
// 画像がnullでない場合は、画像を表示する。nullの時は、ドラッグ&ドロップエリアを表示する
export const ImageUploadForm: React.FC<ImageUploadFormProps> = (props) => {
  const {
    isDragging,
    onDragOver,
    onDragLeave,
    onDrop,
    onFileSelect,
    onDelete,
  } = useImageUpload(props.onImageUpload);
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className="upload-container"
      onClick={() => {
        fileInputRef.current?.click();
      }}
    >
      <div className="upload-form">
        {!props.img ? (
          <div
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            className={`drop-zone ${isDragging ? 'dragging' : ''}`}
          >
            <BodySubTextIons
              text={'ドラッグ&ドロップで画像をアップロード'}
              size="extra-small"
              className="drop-text"
            />

            <BodySubTextIons
              text={'または'}
              size="extra-small"
              className="drop-text"
            />

            <input
              ref={fileInputRef}
              role="input"
              type="file"
              className="file-input"
              accept="image/*"
              multiple
              onChange={onFileSelect}
            />

            <RoundPurpleButtonIons size="small">
              ファイルを選択
            </RoundPurpleButtonIons>
          </div>
        ) : (
          <IdeaImgIons
            img_src={
              typeof props.img === 'string'
                ? props.img
                : URL.createObjectURL(props.img)
            }
            alt="upload-image"
            onDelete={onDelete}
          />
        )}
      </div>
    </div>
  );
};
