import React from 'react';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { HomeSubTitleTextIons } from '../../../ions/text/subtitle/HomeSubTitle.Ions';
import { HomeTitleTextIons } from '../../../ions/text/title/HomeTitleText.Ions';
import './HomeTitleAndSubtitle.css';

interface HomeTitleAndSubtitleProps {
  title: string;
  title_size?: TextSizeType;
  subTitle: string;
  subTitle_size?: TextSizeType;
}

const HomeTitleAndSubtitle: React.FC<HomeTitleAndSubtitleProps> = React.memo(
  (props) => {
    return (
      <section className="home-title-subtitle-container">
        <HomeTitleTextIons
          size={props.title_size || 'extra-large'}
          text={props.title}
        />
        <HomeSubTitleTextIons
          size={props.subTitle_size || 'large'}
          text={props.subTitle}
        />
      </section>
    );
  },
);

export default HomeTitleAndSubtitle;
