import CheckIcon from '@mui/icons-material/Check';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import { useVisibilityController } from '../../../../hooks/useVisibilityController';
import { openRegisterPopup } from '../../../../redux/slice/registerPopupSlice';
import { loginWithMail } from '../../../../redux/user/mailLoginThunk';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import { SNSRoundButton } from '../../../ions/sns/round/SNSRoundButton';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import './LoginPopup.css';

interface PopupProps {
  isSP: boolean;
  open: boolean;
  onClose: () => void;
}

const LoginPopup: React.FC<PopupProps> = React.memo(
  ({ isSP, open, onClose }) => {
    const dispatch = useDispatch();

    // ログイン処理実行の結果
    const [login_result, setLoginResult] = React.useState<boolean | null>(null);
    const { isVisible, isClosing } = useVisibilityController(
      open,
      login_result ? 1000 : 0,
    );

    // ログイン用のテキストフィールド
    const { value: mail, handleChange: handleMailChange } = useTextfield('');
    const { value: password, handleChange: handlePasswordChange } =
      useTextfield('');

    // メールアドレスかパスワードが変更された際にエラーメッセージを消す
    useEffect(() => {
      setLoginResult(null);
    }, [mail, password]);

    // ログイン用の仮設関数
    const handleLogin = useCallback(async () => {
      const result: boolean = await dispatch(
        loginWithMail({ mail, password }) as any,
      );
      if (result) {
        setLoginResult(true);
        await onClose();
      } else {
        setLoginResult(false);
        alert('ログインに失敗しました');
      }
    }, [dispatch, mail, password, onClose]);

    // 新規会員登録をクリックした際の処理
    const handleOpenRegister = useCallback(() => {
      onClose();
      dispatch(openRegisterPopup());
    }, [dispatch]);

    return (
      <DefaultPopupIons
        open={isVisible}
        onClose={onClose}
        size={login_result && isClosing ? 'normal' : 'large'}
        zIndex={1001}
      >
        {login_result && isClosing ? (
          <div className={`success-popup ${isSP ? 'sp' : ''}`}>
            <div className={`check-container`}>
              <CheckIcon sx={{ color: 'white', fontSize: 48 }} />
            </div>
            <BlackTitleTextIons
              text="ログインに成功しました"
              size={isSP ? 'normal' : 'medium'}
              className="success-popup-text"
            />
          </div>
        ) : (
          <div className={`login-popup ${isSP ? 'sp' : ''}`}>
            <header>
              <BlackTitleTextIons text="ログイン" size="normal" />
            </header>

            <div className={`login-popup-content ${isSP ? 'sp' : ''}`}>
              <main
                className={`login-popup-auth-container ${isSP ? 'sp' : ''}`}
              >
                <form
                  className={`login-popup-auth-textfields ${isSP ? 'sp' : ''}`}
                >
                  <DefaultTextFieldIons
                    label="メールアドレス"
                    size={isSP ? 'small' : 'medium'}
                    value={mail}
                    onChange={handleMailChange}
                    error={
                      login_result === false
                        ? 'メールアドレスまたはパスワードが違います'
                        : null
                    }
                  />
                  <DefaultTextFieldIons
                    label="パスワード"
                    size={isSP ? 'small' : 'medium'}
                    value={password}
                    type="password"
                    onChange={handlePasswordChange}
                    error={
                      login_result === false
                        ? 'メールアドレスまたはパスワードが違います'
                        : null
                    }
                  />
                </form>

                <aside className="login-popup-auth-links">
                  <BodyTextIonsClickable
                    text="パスワードを忘れた場合"
                    size="extra-small"
                    onClick={() => {
                      alert('パスワードを忘れた場合はこの画面が見えます');
                    }}
                  />
                  <BodyTextIonsClickable
                    text="新規会員登録はこちら"
                    size="extra-small"
                    onClick={handleOpenRegister}
                  />
                </aside>

                <div className="login-popup-form-submit-button">
                  <RoundPurpleButtonIons
                    width={'100%'}
                    size={'normal'}
                    onClick={handleLogin}
                  >
                    ログイン
                  </RoundPurpleButtonIons>
                </div>
              </main>

              <nav
                className={`login-popup-sns-buttons-container ${isSP ? 'sp' : ''}`}
              >
                {['Google', 'Line'].map((type) => (
                  <SNSRoundButton
                    key={type}
                    text="ログイン"
                    type={type as any}
                    onClick={() => {
                      alert('onClick');
                    }}
                  />
                ))}
              </nav>
            </div>
          </div>
        )}
      </DefaultPopupIons>
    );
  },
);

export default LoginPopup;
