import React from 'react';
import { DUMMY_TAGS } from '../../../../constants/dummy';
import { TagType } from '../../../../types/api/tags/TagsType';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import CheckboxGrid from '../../../molecules/checkbox/grid/CheckboxGrid';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';
import './TagsCheckboxGrid.css';

interface TagsCheckboxGridProps {
  tags: string[];
  error: string | null;
  handleChange: (tag: string) => void;
  columns: number;
  require: boolean | null;
  num?: number;
  max?: number;
}

const TagsCheckboxGrid: React.FC<TagsCheckboxGridProps> = React.memo(
  (props) => {
    return (
      <section className="tag-checkbox-grid-wrapper">
        {props.num ? (
          <NumAndTitle
            num={props.num}
            title="カテゴリーを選択"
            num_size="small"
            title_size="small"
          />
        ) : props.require === null ? (
          <BodyTextBoldIons size="small" text="カテゴリーを選択" />
        ) : (
          <RequireAndTitle require={true} title="カテゴリーを選択" />
        )}
        <CheckboxGrid
          columns={props.columns}
          error={props.error}
          max={props.max}
          num_of_checked={props.tags.length}
          options={DUMMY_TAGS.map((tag: TagType) => {
            return {
              label: tag.name,
              value: tag.tag_id,
              checked: props.tags.includes(tag.tag_id),
              onChange: () => {
                props.handleChange(tag.tag_id);
              },
              disabled: false,
              size: 'small',
            };
          })}
        />
      </section>
    );
  },
);

export default TagsCheckboxGrid;
