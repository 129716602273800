export type SearchPageSortQuery =
  | null
  | 'new'
  | 'popular'
  | 'recent'
  | 'featured'
  | 'realized';

// 配列とタイトルを連携させるオブジェクト
export const searchPageSortQueryMap = {
  new: '新しい',
  popular: '人気の',
  recent: '最近見た',
  featured: 'おすすめの',
  realized: '実現した',
};

// パース関数：無効な値の場合は 'home' を返す
export const parseSearchSortQuery = (
  view?: string | null,
): SearchPageSortQuery => {
  if (view && Object.keys(searchPageSortQueryMap).includes(view)) {
    return view as SearchPageSortQuery;
  } else {
    return null;
  }
};

// タイトル取得関数
export const getSearchPageTitle = (
  query: SearchPageSortQuery,
): string | null => (query ? searchPageSortQueryMap[query] : null);
