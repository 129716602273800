import React from 'react';
import useOpen from '../../../../hooks/useOpen';
import { IdeaActivityType } from '../../../../types/api/idea/IdeaTypes';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import './ActivityCard.Molecules.css';

type ActivityCardProps = {
  activity: IdeaActivityType;
};

export const ActivityCardMolecules: React.FC<ActivityCardProps> = React.memo(
  (props) => {
    const { activity } = props;
    const { open, handleChangeOpen } = useOpen();
    return (
      <div className="activity-card-molecules-container">
        <header className="activity-card-molecules-header">
          <UserIconImgIons
            img_src={activity.user_img_src}
            user_name={activity.user_name}
            size="small"
          />
          <div className="activity-card-molecules-header-texts">
            <BodyTextBoldIons size="small" text={activity.title} />
            <BodyTextIons size="extra-small" text={activity.createdAt} />
          </div>
        </header>
        <main className="activity-card-molecules-main">
          <BodyTextIons
            rows={open ? undefined : 2}
            size="small"
            text={activity.message}
          />
          {!open && (
            <RoundWhiteButtonIons size="normal" onClick={handleChangeOpen}>
              もっと読む
            </RoundWhiteButtonIons>
          )}
        </main>
      </div>
    );
  },
);
