import React from 'react';
import { sendIdeaCommentProps } from '../../../../api/idea/comment/ideaCommentAPI.type';
import useOpen from '../../../../hooks/useOpen';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import { IdeaCommentType } from '../../../../types/api/idea/IdeaTypes';
import TextFieldAtoms from '../../../atoms/textfield/TextField.Atoms';
import SettingButtonIons from '../../../ions/button/setting/SettingButton.Ions';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import { WhiteTextIons } from '../../../ions/text/white/WhiteText.Ions';
import './CommentCard.Molecules.css';

type IdeaCardProps = {
  comment: IdeaCommentType;
  onSendComment: (input: sendIdeaCommentProps) => void;
};

export const CommentCardMolecules: React.FC<IdeaCardProps> = React.memo(
  (props) => {
    const { comment } = props;
    const { open, handleOpen, handleClose } = useOpen();
    const { value, handleChange } = useTextfield();
    return (
      <div className="comment-card-molecules-container">
        <header className="comment-card-molecules-header">
          <UserIconImgIons
            user_name={comment.user_name}
            img_src={comment.user_img_src}
            size="small"
          />

          <div className="comment-card-molecules-header-texts">
            <div className="comment-card-molecules-header-texts-user-setting">
              <div className="comment-card-molecules-header-texts-user">
                <BodyTextIons size="small" text={comment.user_name} />
                <WhiteTextIons
                  size="extra-small"
                  text={comment.user_type}
                  className="comment-card-molecules-header-texts-user-type"
                />
              </div>
              <SettingButtonIons size="small" onClick={() => console.log('')} />
            </div>
            <BodyTextIons size="extra-small" text={comment.createdAt} />
          </div>
        </header>
        <main className="comment-card-molecules-main">
          <BodyTextIons size="small" text={comment.comment} />
        </main>
        <div className="comment-card-molecules-footer">
          <BodyTextIons size="extra-small" text={`♡ ${comment.num_of_likes}`} />
          <BodyTextIons
            size="extra-small"
            text={'返信する'}
            onClick={() => handleOpen()}
          />
        </div>
        {open && (
          <div className="comment-card-molecules-replies">
            <div className="comment-card-molecules-reply">
              <UserIconImgIons
                user_name={comment.user_name}
                img_src={comment.user_img_src}
                size="small"
              />
              <TextFieldAtoms
                placeholder="返信を入力"
                value={value}
                onChange={handleChange}
                label={null}
                design_type="underline"
                error={null}
              />
            </div>
            <div className="comment-card-molecules-reply-buttons">
              <BodyTextIons
                size="extra-small"
                text={'キャンセル'}
                onClick={() => handleClose()}
              />
              <BodyTextIonsClickable
                size="extra-small"
                text={'投稿する'}
                onClick={() =>
                  props.onSendComment({
                    idea_id: comment.idea_id,
                    to_comment_id: comment.comment_id,
                    comment: value,
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);
