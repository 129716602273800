export const validateMail = (value: string): string | null => {
  if (value === '') return null;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value) ? null : '無効なメールアドレスです';
};

export const validatePassword = (value: string): string | null => {
  if (value === '') return null;
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
  return passwordRegex.test(value)
    ? null
    : 'パスワードは英数字を含む6文字以上でなければなりません';
};
