import MenuIcon from '@mui/icons-material/Menu';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOpen from '../../../hooks/useOpen';
import useTextfield from '../../../hooks/useTextfield.hooks';
import { logout } from '../../../redux/auth/authSlice';
import { openLoginPopup } from '../../../redux/slice/loginPopupSlice';
import { openRegisterPopup } from '../../../redux/slice/registerPopupSlice';
import { RootState } from '../../../redux/store';
import { PageNavigation } from '../../../utils/pageNavigation';
import DrawerAtoms from '../../atoms/drawer/Drawer.Atoms';
import FullRoundRegisterButtonIons from '../../ions/button/fullRound/Purple/FullRoundRegisterButton.Ions';
import FullRoundLoginButtonIons from '../../ions/button/fullRound/White/FullRoundLoginButton.Ions';
import UserIconImgIons from '../../ions/img/icon/UserIconImg.Ions';
import ImgLogoIons from '../../ions/img/logo/ImgLogo.Ions';
import DefaultPopoverIons from '../../ions/popover/DefaultPopover.Ions';
import DefaultSearchTextFieldIons from '../../ions/textfield/DefaultSearchTextField';
import { HeaderDrawerMolecules } from '../../molecules/drawer/HeaderDrawer.Molecules';
import UserPopover from '../userPopover/UserPopover';
import './AppHeader.css';

interface AppHeaderProps {
  isSP?: boolean;
  logo_img_src: string;
  user_img_src: string;
}

const AppHeader: React.FC<AppHeaderProps> = React.memo((props) => {
  const dispatch = useDispatch();
  // ログイン状態を管理するためのReduxのステートを取得
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const handleOpenLoginPopup = useCallback(() => {
    dispatch(openLoginPopup());
  }, [dispatch]);

  const handleOpenRegisterPopup = useCallback(() => {
    dispatch(openRegisterPopup());
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    PageNavigation.goToHomePage();
  }, [dispatch]);

  const { open, handleClose, handleOpen } = useOpen();
  const { value: search, handleChange: handleChangeSearch } = useTextfield('');
  const onSearch = useCallback((value: string) => {
    PageNavigation.goToSearchPage({ keyword: value });
  }, []);

  return (
    <>
      <header className={`app-header ${props.isSP ? 'sp' : ''}`}>
        <section className="app-header-logo-search-wrapper">
          <ImgLogoIons
            size={props.isSP ? 'small' : 'medium'}
            img_src={props.logo_img_src}
            onClick={() => PageNavigation.goToHomePage()}
          />
          {!props.isSP && (
            <DefaultSearchTextFieldIons
              placeholder="アイデア、お題を検索する"
              onChange={handleChangeSearch}
              onSearch={(value) => onSearch(value)}
              value={search}
            />
          )}
        </section>
        {props.isSP ? (
          <MenuIcon onClick={() => handleOpen()} />
        ) : isLoggedIn ? (
          <DefaultPopoverIons
            trigger="click"
            content={<UserPopover isSP={props.isSP} />}
            placement="bottom-right"
          >
            <UserIconImgIons
              user_name="ユーザー名"
              img_src={props.user_img_src}
              size={props.isSP ? 'medium' : 'large'}
            />
          </DefaultPopoverIons>
        ) : (
          <div className="auth-buttons">
            <FullRoundLoginButtonIons
              size={'small'}
              onClick={handleOpenLoginPopup}
            />
            <FullRoundRegisterButtonIons
              size={'small'}
              onClick={handleOpenRegisterPopup}
            />
          </div>
        )}
      </header>

      <DrawerAtoms
        isOpen={open}
        onClose={handleClose}
        direction="right"
        width="300px"
      >
        <HeaderDrawerMolecules
          isLoggedin={isLoggedIn}
          onClose={handleClose}
          onSearch={(value) => onSearch(value)}
          options={[
            {
              label: 'IDEA工房の使い方',
              onClick: () => PageNavigation.goToHomePage(),
            },
            {
              label: 'IDEA一覧へ',
              onClick: () => PageNavigation.goToMyPage({ mode: 'my' }),
            },
            {
              label: 'お題一覧へ',
              onClick: () => PageNavigation.goToMyPage({ mode: 'my' }),
            },
            {
              label: 'マイページへ',
              onClick: () => PageNavigation.goToMyPage({ mode: 'setting' }),
            },
            {
              label: 'ログアウト',
              onClick: () => handleLogout(),
            },
          ]}
        />
      </DrawerAtoms>
    </>
  );
});

export default AppHeader;
