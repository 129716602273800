import React from 'react';
import { ChatDetailType } from '../../../types/api/chat/ChatTypes';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { RoomCardType } from '../../../types/api/room/RoomTypes';
import { UserDetailType } from '../../../types/api/user/UserTypes';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import UserIconAndName from '../../molecules/user/UserIconAndName';
import ChatGrid from '../../organisms/grid/chatGrid/ChatGrid';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import RoomCardGrid from '../../organisms/grid/roomCardGrid/RoomCardGrid';
import MypageNavTexts from '../../organisms/navTexts/MypageNavTexts';
import IdeaTypeButtonsGrid from '../../organisms/radio/IdeaType/IdeaTypeButtonsGrid';
import UserInfoForm from '../../organisms/textfield/form/UserInfoForm';
import './MyPageTemplate.css';

interface MyPageTemplateProps {
  user: UserDetailType;
  user_ideas: IdeaCardType[];
  rooms: RoomCardType[];
  chat_room: RoomCardType;
  chats: ChatDetailType[];
  mode?: string;
  title?: string;
  size?: 'small' | 'medium' | 'large' | 'none';
  chat_user_id: string | null;
  page?: number;
  max_page?: number;
}

const MyPageTemplatePC: React.FC<MyPageTemplateProps> = (props) => {
  let width: string;
  let columns: number;

  switch (props.size) {
    case 'small':
      width = '750px';
      columns = 3;
      break;
    case 'medium':
      width = '850px';
      columns = 3;
      break;
    case 'large':
      width = '950px';
      columns = 3;
      break;
    default:
      width = '950px';
      columns = 3;
  }

  if (props.size === 'none') {
    return null;
  }
  return (
    <div className="mypage-template">
      <main className="mypage-template-main" style={{ width: width }}>
        <nav className="mypage-template-main-nav-texts">
          <UserIconAndName
            user_img_src={props.user.img_src}
            user_name={props.user.user_name}
            icon_size="medium"
          />
          <MypageNavTexts />
        </nav>

        <section className="mypage-template-main-contents">
          <div className="mypage-template-titles">
            {props.title && (
              <BodyTextBoldIons text={props.title} size="medium" />
            )}
          </div>
          {props.mode === 'my' ? (
            <>
              <IdeaTypeButtonsGrid columns={3} />
              <IdeaCardGrid
                isSP={false}
                card_text_size="small"
                columns={columns}
                ideas={props.user_ideas}
                page={props.page}
                max_page={props.max_page}
              />
            </>
          ) : props.mode === 'chat' ? (
            props.chat_user_id ? (
              <ChatGrid
                to_user_name={props.chat_room.to_user_name}
                login_user_id={props.user.user_id}
                chats={props.chats}
                size="extra-small"
              />
            ) : (
              <RoomCardGrid rooms={props.rooms} />
            )
          ) : props.mode === 'info' ? (
            <UserInfoForm columns={5} user={props.user} />
          ) : null}
        </section>
      </main>
    </div>
  );
};

export default MyPageTemplatePC;
