import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { sendIdeaActivity } from '../../../api/idea/activity/ideaActivityAPI';
import { sendIdeaActivityProps } from '../../../api/idea/activity/ideaActivityAPI.type';
import { sendIdeaComment } from '../../../api/idea/comment/ideaCommentAPI';
import { sendIdeaCommentProps } from '../../../api/idea/comment/ideaCommentAPI.type';
import { likeIdea } from '../../../api/idea/like/IdeaLikeAPI';
import {
  DUMMY_IDEA_DETAIL_DATA,
  MULTI_DUMMY_IDEA_ACTIVITY_DATA,
  MULTI_DUMMY_IDEA_CARD_DATA,
  MULTI_DUMMY_IDEA_COMMENT_DATA,
} from '../../../constants/dummy';
import { openLoginPopup } from '../../../redux/slice/loginPopupSlice';
import { RootState } from '../../../redux/store';
import {
  IdeaActivityType,
  IdeaCardType,
  IdeaCommentType,
  IdeaDetailType,
} from '../../../types/api/idea/IdeaTypes';
import {
  IdeaPageViewQuery,
  parseIdeaViewQuery,
} from '../../../types/query/ideaQuery';
import { IdeaTemplateProps } from '../../templates/idea/IdeaTemplate.type';
import IdeaTemplatePC from '../../templates/idea/IdeaTemplatePC';
import IdeaTemplateSP from '../../templates/idea/IdeaTemplateSP';

const IdeaPage = () => {
  const dispatch = useDispatch();
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  // ログイン状態の取得
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  // URLパラメータからviewを取得
  const [searchParams] = useSearchParams();
  const view: IdeaPageViewQuery = parseIdeaViewQuery(searchParams.get('view'));

  // ダミーデータ
  const idea: IdeaDetailType = DUMMY_IDEA_DETAIL_DATA;
  const idea_comments: IdeaCommentType[] = MULTI_DUMMY_IDEA_COMMENT_DATA;
  const suggest_ideas: IdeaCardType[] = MULTI_DUMMY_IDEA_CARD_DATA;
  const idea_activities: IdeaActivityType[] = MULTI_DUMMY_IDEA_ACTIVITY_DATA;

  // いいねする機能をuseCallbackでラッピング
  const handleLike = useCallback(() => {
    if (!isLoggedIn) dispatch(openLoginPopup());
    else likeIdea({ idea_id: idea.idea_id });
  }, [idea.idea_id, isLoggedIn]);

  // コメントを送信する機能をuseCallbackでラッピング
  const handleSendIdeaComment = useCallback(
    (input: sendIdeaCommentProps) => {
      sendIdeaComment(input);
    },
    [idea.idea_id],
  );

  // 活動報告を送信する関数をuseCallbackでラッピング
  const handleSendIdeaActivity = useCallback(
    (input: sendIdeaActivityProps) => {
      sendIdeaActivity(input);
    },
    [idea.idea_id],
  );

  // テンプレートの共通引数のラッピング
  const commonProps: IdeaTemplateProps = {
    view,
    idea,
    idea_comments,
    suggest_ideas,
    idea_activities,
    sendIdeaComment: handleSendIdeaComment,
    sendIdeaActivity: handleSendIdeaActivity,
    likeIdea: handleLike,
  };

  return isXS ? (
    <IdeaTemplateSP {...commonProps} />
  ) : (
    <IdeaTemplatePC
      size={isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'}
      {...commonProps}
    />
  );
};

export default IdeaPage;
