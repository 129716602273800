import React from 'react';
import './InputAdorementTextField.Atoms.css';
import { InputAdorementTextFieldAtomsProps } from './InputAdorementTextField.type';

const InputAdorementTextFieldAtoms: React.FC<InputAdorementTextFieldAtomsProps> =
  React.memo(
    ({
      value,
      onChange,
      onKeyDown,
      startAdorement,
      endAdorement,
      placeholder = '',
      className = '',
    }) => {
      return (
        <div className="input-adorement-container">
          {startAdorement && (
            <div className="input-adorement-start">{startAdorement}</div>
          )}
          <textarea
            value={value}
            onChange={onChange}
            onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (
                event.key === 'Enter' &&
                (event.metaKey || event.ctrlKey) &&
                onKeyDown
              ) {
                onKeyDown(value);
              }
            }}
            placeholder={placeholder}
            className={`input-adorement-input ${className}`}
          />
          {endAdorement && (
            <div className="input-adorement-end">{endAdorement}</div>
          )}
        </div>
      );
    },
  );

export default InputAdorementTextFieldAtoms;
