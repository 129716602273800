import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom';
import AppHeader from './component/organisms/appHeader/AppHeader';
import HomePage from './component/pages/HomePage';
import IdeaEditPage from './component/pages/idea/IdeaEditPage';
import IdeaInvestConfirmPage from './component/pages/idea/IdeaInvestConfirmPage';
import IdeaInvestPage from './component/pages/idea/IdeaInvestPage';
import IdeaPage from './component/pages/idea/IdeaPage';
import IntroducePage from './component/pages/IntroducePage';
import MyPage from './component/pages/MyPage';
import IdeaPostPopup from './component/pages/popup/idea/IdeaPostPopup';
import LoginPopup from './component/pages/popup/login/LoginPopup';
import RegisterPopup from './component/pages/popup/register/RegisterPopup';
import RequestPage from './component/pages/RequestPage';
import SearchPage from './component/pages/SearchPage';
import useResizeListener from './hooks/useResizeListener';
import { closeIdeaPostPopup } from './redux/slice/ideaPostPopupSlice';
import { closeLoginPopup } from './redux/slice/loginPopupSlice';
import { closeRegisterPopup } from './redux/slice/registerPopupSlice';
import { RootState } from './redux/store';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Hiragino Sans', // ヒラギノフォントを指定
      'Arial', // 代替フォント
      'sans-serif', // サンセリフフォント
    ].join(','),
  },
});
const router = createBrowserRouter([
  {
    element: (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ScrollRestoration />
        <Outlet />
      </ThemeProvider>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/ideas/:id',
        element: <IdeaPage />,
      },
      {
        path: '/ideas/:id/edit',
        element: <IdeaEditPage />,
      },
      {
        path: '/ideas/:id/invest',
        element: <IdeaInvestPage />,
      },
      {
        path: '/ideas/:id/invest/confirm',
        element: <IdeaInvestConfirmPage />,
      },
      {
        path: '/search',
        element: <SearchPage />,
      },
      {
        path: '/mypage',
        element: <MyPage />,
      },
      {
        path: '/introduce',
        element: <IntroducePage />,
      },
      {
        path: '/request',
        element: <RequestPage />,
      },
    ],
  },
]);

function AppRouter() {
  const dispatch = useDispatch();
  const { isOpen: isLoginPopupOpen } = useSelector(
    (state: RootState) => state.loginPopup,
  );
  const { isOpen: isRegisterPopupOpen } = useSelector(
    (state: RootState) => state.registerPopup,
  );
  const { isOpen: isIdeaPostPopupOpen } = useSelector(
    (state: RootState) => state.ideaPostPopup,
  );

  const handleCloseLoginPopup = useCallback(() => {
    dispatch(closeLoginPopup());
  }, [dispatch]);

  const handleCloseRegisterPopup = useCallback(() => {
    dispatch(closeRegisterPopup());
  }, [dispatch]);

  const handleCloseIdeaPostPopup = useCallback(() => {
    dispatch(closeIdeaPostPopup());
  }, [dispatch]);

  useResizeListener();
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );

  return (
    <>
      <AppHeader
        logo_img_src="/images/static/REIDEA-LOGO.png"
        user_img_src="/images/static/REIDEA-BANNER.jpg"
        isSP={isXS}
      />

      <RouterProvider router={router} />

      <LoginPopup
        isSP={isXS}
        open={isLoginPopupOpen}
        onClose={handleCloseLoginPopup}
      />
      <RegisterPopup
        isSP={isXS}
        open={isRegisterPopupOpen}
        onClose={handleCloseRegisterPopup}
      />

      <IdeaPostPopup
        isSP={isXS}
        open={isIdeaPostPopupOpen}
        onClose={handleCloseIdeaPostPopup}
      />
    </>
  );
}

export default AppRouter;
