import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import { openLoginPopup } from '../../../../redux/slice/loginPopupSlice';
import { validateMail } from '../../../../utils/validate/validateMail';
import { validatePassword } from '../../../../utils/validate/validatePassword';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import { SNSRoundButton } from '../../../ions/sns/round/SNSRoundButton';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import './RegisterPopup.css';

interface PopupProps {
  isSP: boolean;
  open: boolean;
  onClose: () => void;
}

const RegisterPopup: React.FC<PopupProps> = React.memo(
  ({ isSP, open, onClose }) => {
    const dispatch = useDispatch();

    const {
      value: mail,
      error: error_mail,
      handleChange: handleMailChange,
    } = useTextfield('', validateMail);
    const {
      value: password,
      error: error_password,
      handleChange: handlePasswordChange,
    } = useTextfield('', validatePassword);

    // ログインをクリックした際の処理
    const handleOpenLogin = useCallback(() => {
      onClose();
      dispatch(openLoginPopup());
    }, [dispatch]);

    return (
      <DefaultPopupIons open={open} onClose={onClose} size="large" zIndex={101}>
        <div className={`register-popup ${isSP ? 'sp' : ''}`}>
          <header>
            <BlackTitleTextIons text="新規会員登録" size="normal" />
          </header>

          <div className={`register-popup-content ${isSP ? 'sp' : ''}`}>
            <main
              className={`register-popup-auth-container ${isSP ? 'sp' : ''}`}
            >
              <form
                className={`register-popup-auth-textfields ${isSP ? 'sp' : ''}`}
              >
                <DefaultTextFieldIons
                  label="メールアドレス"
                  size={isSP ? 'small' : 'medium'}
                  value={mail}
                  onChange={handleMailChange}
                  error={error_mail}
                />
                <DefaultTextFieldIons
                  label="パスワード"
                  size={isSP ? 'small' : 'medium'}
                  value={password}
                  onChange={handlePasswordChange}
                  error={error_password}
                />
              </form>

              <aside className="register-popup-auth-links">
                <BodyTextIonsClickable
                  text="既にアカウントをお持ちの方はこちら"
                  size="extra-small"
                  onClick={handleOpenLogin}
                />
              </aside>

              <div className="register-popup-form-submit-button">
                <RoundPurpleButtonIons
                  width="100%"
                  size="normal"
                  onClick={() => {
                    alert('onClick');
                  }}
                >
                  登録する
                </RoundPurpleButtonIons>
              </div>
            </main>

            <nav
              className={`register-popup-sns-buttons-container ${isSP ? 'sp' : ''}`}
            >
              {['Google', 'Line'].map((type) => (
                <SNSRoundButton
                  key={type}
                  text="登録"
                  type={type as any}
                  onClick={() => {
                    alert('onClick');
                  }}
                />
              ))}
            </nav>
          </div>
        </div>
      </DefaultPopupIons>
    );
  },
);

export default RegisterPopup;
