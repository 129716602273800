import { useCallback, useState } from 'react';

interface UseTextValueProps {
  value: string | null;
  handleChange: (value: string | null) => void;
  handleReset: () => void;
}

const useTextValueWithNull = (init?: string | null): UseTextValueProps => {
  const [value, setValue] = useState<string | null>(init ?? null);

  const handleChange = useCallback((newValue: string | null) => {
    setValue(newValue);
  }, []);

  const handleReset = useCallback(() => {
    setValue('');
  }, []);

  return { value, handleChange, handleReset };
};

export default useTextValueWithNull;
