import React from 'react';
import { useSelector } from 'react-redux';
import { sendIdeaCommentProps } from '../../../../api/idea/comment/ideaCommentAPI.type';
import { RootState } from '../../../../redux/store';
import {
  IdeaCommentType,
  IdeaDetailType,
} from '../../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import IdeaComments from '../../../organisms/idea/comment/IdeaComments';
import './../IdeaTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  idea_comments: IdeaCommentType[];
  title_size?: TextSizeType;
  sendIdeaComment: (input: sendIdeaCommentProps) => void;
}

const IdeaCommentTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const user = useSelector((state: RootState) => state.user);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return (
    <div className="idea-comment-template">
      <BlackTitleTextIons text="みんなの声" size={props.title_size} />
      <IdeaComments
        idea_id={props.idea.idea_id}
        idea_comments={props.idea_comments}
        login_user_img_src={user.img_src}
        login_user_name={user.user_name}
        onSendComment={props.sendIdeaComment}
        isLoggedIn={isLoggedIn}
      />
    </div>
  );
};

export default IdeaCommentTemplate;
