import { createIdeaType } from './createIdeaAPI.type';

// アイデアを作成するAPI
export const createIdea = async (input: createIdeaType) => {
  const response = await fetch(`/api/ideas/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(input),
  });

  if (!response.ok) {
    // throw new Error(`Failed to send comment: ${response.statusText}`);
    alert('Input:\n' + String(JSON.stringify(input)));
  }

  return response.json();
};
