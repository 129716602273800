export const NAV_MYPAGE_OPTIONS: any[] = [
  { label: 'あなたのアイデア', value: 'my' },
  { label: '事業者とのチャット', value: 'chat' },
  { label: 'ユーザー情報', value: 'info' },
];
export type NavMypageValue = (typeof NAV_MYPAGE_OPTIONS)[number]['value'];
export const getNavMypageLabel = (value: NavMypageValue): string => {
  const option = NAV_MYPAGE_OPTIONS.find((option) => option.value === value);
  return option?.label ?? value;
};
export const getNavMypageValue = (
  label: string,
): NavMypageValue | undefined => {
  const option = NAV_MYPAGE_OPTIONS.find((option) => option.label === label);
  return option?.value as NavMypageValue | undefined;
};
