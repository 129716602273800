import { configureStore } from '@reduxjs/toolkit';
import { UserCardType } from '../types/api/user/UserTypes';
import authReducer, { AuthState } from './auth/authSlice';
import ideaPostPopupReducer, {
  IdeaPostPopupState,
} from './slice/ideaPostPopupSlice';
import loginPopupReducer, { LoginPopupState } from './slice/loginPopupSlice';
import registerPopupReducer, {
  RegisterPopupState,
} from './slice/registerPopupSlice';
import screenSizeReducer, { ScreenSizeState } from './slice/screenSizeSlice';
import userReducer from './user/userSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    loginPopup: loginPopupReducer,
    registerPopup: registerPopupReducer,
    ideaPostPopup: ideaPostPopupReducer,
    screenSize: screenSizeReducer,
  },
});

export type RootState = {
  auth: AuthState;
  user: UserCardType;
  loginPopup: LoginPopupState;
  registerPopup: RegisterPopupState;
  ideaPostPopup: IdeaPostPopupState;
  screenSize: ScreenSizeState;
};
export type AppDispatch = typeof store.dispatch;
