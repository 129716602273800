import React from 'react';
import CircleWhiteButtonIons from '../../ions/button/circle/white/CircleWhiteButton.Ions';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import './IdeaFooter.css';

interface IdeaFooterProps {
  text?: string;
  onClick?: () => void;
  likeIdea: () => void;
  num_of_likes?: number;
}

const IdeaFooter: React.FC<IdeaFooterProps> = React.memo((props) => {
  return (
    <footer className={`idea-footer-wrapeer`}>
      <div className="idea-footer-heart-buttons">
        <CircleWhiteButtonIons size="extra-small" onClick={props.likeIdea}>
          ♡
        </CircleWhiteButtonIons>
        <BodyTextIons
          text={String(props.num_of_likes ?? 0)}
          size="extra-small"
        />
      </div>
      <RoundPurpleButtonIons
        size="medium"
        width={'80%'}
        onClick={props.onClick}
      >
        {props.text}
      </RoundPurpleButtonIons>
    </footer>
  );
});

export default IdeaFooter;
