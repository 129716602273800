import React from 'react';
import { IdeaDetailType } from '../../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../../atoms/text/Text.type';
import { BlackTitleTextIons } from '../../../ions/text/title/BlackTitleText.Ions';
import IdeaApealAndStory from '../../../organisms/idea/apealAndStory/IdeaApealAndStory';
import RequestCard from '../../../organisms/request/RequestCard';
import './../IdeaTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  title_size?: TextSizeType;
}

const IdeaStoryTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const { idea } = props;
  return (
    <div className="idea-story-template">
      <BlackTitleTextIons text="ストーリー" size={props.title_size} />
      <IdeaApealAndStory idea={idea} />
      <RequestCard
        request_id={idea.request_id}
        img_src={idea.request_img_src}
        title={idea.request_title}
        title_size={props.title_size}
      />
    </div>
  );
};

export default IdeaStoryTemplate;
