import React from 'react';
import InputAdorementTextFieldAtoms from '../../atoms/textfield/inputAdorement/InputAdorementTextField.Atoms';
import { InputAdorementTextFieldAtomsProps } from '../../atoms/textfield/inputAdorement/InputAdorementTextField.type';

const DefaultInputAdorementTextField: React.FC<InputAdorementTextFieldAtomsProps> =
  React.memo((props) => {
    return <InputAdorementTextFieldAtoms {...props} />;
  });

export default DefaultInputAdorementTextField;
