import React from 'react';
import useTextfield from '../../../../../hooks/useTextfield.hooks';
import RadioButtonAtoms from '../../../../atoms/radio/RadioButton.Atoms';
import { RadioButtonSizeType } from '../../../../atoms/radio/RadioButton.type';
import { TextSizeType } from '../../../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import IdeaImgIons from '../../../../ions/img/IdeaImg.Ions';
import { BodySubTextIons } from '../../../../ions/text/body/BodySubText.Ions';
import { BodyTextIons } from '../../../../ions/text/body/BodyText.Ions';
import DefaultTextFieldIons from '../../../../ions/textfield/DefaultTextField.Ions';
import { ImageUploadForm } from '../../../../molecules/form/ImageUploadForm';
import NumAndTitle from '../../../../molecules/text/numTitle/NumAndTitle';
import RequireAndTitle from '../../../../molecules/text/requireTitle/RequireAndTitle';
import './IdeaImageGeneForm.css';

interface IdeaImageGeneFormProps {
  num?: number;
  size?: RadioButtonSizeType;
  require?: boolean | null;
  img: string | File | null;
  handleChangeImg: (value: File | null) => void;
  text_size?: TextSizeType;
  generate: boolean;
  handleChangeGenerate: (value: boolean) => void;
  display_generate_form?: boolean;
}

const IdeaImageGeneForm: React.FC<IdeaImageGeneFormProps> = ({
  num,
  size,
  text_size,
  generate,
  img,
  handleChangeImg,
  require,
  handleChangeGenerate,
  display_generate_form = false,
}) => {
  const { value, handleChange } = useTextfield();
  return (
    <section className="image-gene-radio-button-wrapper">
      {num && (
        <NumAndTitle
          num={num}
          title="アイデアの画像を選択"
          num_size="small"
          title_size="small"
        />
      )}
      {(require === true || require === false) && (
        <RequireAndTitle title="アイデアの画像を選択" require={require} />
      )}
      <RadioButtonAtoms
        checked={generate}
        size={size}
        onChange={() => {
          handleChangeGenerate(true);
        }}
      >
        <div
          className={`image-gene-radio-button-cell ${generate ? 'selected' : ''} ${size}`}
        >
          <BodyTextIons
            text={'アイデアの画像をAIが自動で生成する'}
            size={text_size}
            className="image-gene-radio-button-text"
          />
        </div>
      </RadioButtonAtoms>
      <RadioButtonAtoms
        checked={!generate}
        size={size}
        onChange={() => {
          handleChangeGenerate(false);
        }}
      >
        <div
          className={`image-gene-radio-button-cell ${!generate ? 'selected' : ''} ${size}`}
        >
          <BodyTextIons
            text={'アイデアの画像を自分でアップロードする'}
            size={text_size}
            className="image-gene-radio-button-text"
          />
        </div>
      </RadioButtonAtoms>
      {generate ? (
        display_generate_form ? (
          <div className="image-gene-form">
            {img ? (
              <IdeaImgIons
                img_src={
                  typeof img === 'string' ? img : URL.createObjectURL(img)
                }
                onDelete={() => handleChangeImg(null)}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  aspectRatio: '7/3',
                  border: '0.5px solid grey',
                  borderRadius: '5px',
                }}
              >
                <BodySubTextIons
                  size="extra-small"
                  text="画像がまだありません"
                />
              </div>
            )}

            <DefaultTextFieldIons
              value={value}
              placeholder={
                '出力したいアイデアの画像の特徴を記入\n(例: おしゃれなカフェの店内)'
              }
              onChange={handleChange}
              label={null}
              error={null}
              rows={2}
              width={'100%'}
              max={100}
              design_type="underline"
            />
            <RoundPurpleButtonIons width={'70%'}>生成</RoundPurpleButtonIons>
          </div>
        ) : null
      ) : (
        <ImageUploadForm img={img} onImageUpload={handleChangeImg} />
      )}
    </section>
  );
};

export default IdeaImageGeneForm;
