import React from 'react';
import './Drawer.Atoms.css';
import { DrawerAtomsProps } from './Drawer.type';

const DrawerAtoms: React.FC<DrawerAtomsProps> = React.memo(
  ({
    isOpen,
    onClose,
    direction = 'right',
    children,
    width = '300px',
    height = '100%',
  }) => {
    const style = {
      width: ['left', 'right'].includes(direction) ? width : '100%',
      height: ['top', 'bottom'].includes(direction) ? height : '100%',
    };

    return (
      <>
        <div
          className={`drawer-overlay-atoms ${isOpen ? 'visible' : ''}`}
          onClick={onClose}
          role="overlay"
        />
        <div
          className={`drawer-atoms ${direction} ${isOpen ? 'open' : ''}`}
          style={style}
        >
          <div className="drawer-content-atoms">{children}</div>
        </div>
      </>
    );
  },
);

export default DrawerAtoms;
