import React, { ChangeEvent } from 'react';
import { IDEA_APEALPOINT_MAX_LENGTH } from '../../../../constants/max';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import RequireAndTitle from '../../../molecules/text/requireTitle/RequireAndTitle';

interface ButtonProps {
  index: number;
  apealpoint: string | null;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
}

const IdeaApealPointTextfield: React.FC<ButtonProps> = React.memo((props) => {
  return (
    <DefaultTextFieldIons
      value={props.apealpoint || ''}
      error={props.error}
      onChange={props.handleChange}
      width={'100%'}
      label={
        <RequireAndTitle
          require={false}
          title={'アピールポイント' + props.index}
        />
      }
      placeholder="アピールポイントを入力してください"
      max={IDEA_APEALPOINT_MAX_LENGTH}
    />
  );
});

export default IdeaApealPointTextfield;
