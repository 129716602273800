import React from 'react';
import useTextfield from '../../../hooks/useTextfield.hooks';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { UserCardType } from '../../../types/api/user/UserTypes';
import { PageNavigation } from '../../../utils/pageNavigation';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import DefaultSearchTextFieldIons from '../../ions/textfield/DefaultSearchTextField';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import CategoryNavTexts from '../../organisms/navTexts/CategoryNavTexts';
import IdeaSortNavTexts from '../../organisms/navTexts/IdeaSortNavTexts';
import UserNavTexts from '../../organisms/navTexts/UserNavTexts';
import './SearchTemplate.css';

interface SearchTemplateProps {
  title?: string;
  page?: number;
  max_page?: number;
  ideas: IdeaCardType[];
  users: UserCardType[];
}

const SearchTemplateSP: React.FC<SearchTemplateProps> = (props) => {
  const { value, handleChange } = useTextfield();
  return (
    <div className="search-template">
      <main className="search-template-main sp">
        <section className="search-template-main-ideas sp">
          {props.title && <BodyTextBoldIons text={props.title} size="normal" />}
          <DefaultSearchTextFieldIons
            placeholder="キーワードを入力"
            value={value}
            onChange={handleChange}
            onSearch={(value) =>
              PageNavigation.goToSearchPage({ keyword: value })
            }
          />
          <IdeaCardGrid
            isSP
            card_text_size="extra-small"
            columns={2}
            ideas={props.ideas}
            page={props.page}
            max_page={props.max_page}
          />
        </section>
        <nav className="search-template-main-nav-texts sp">
          <BodyTextBoldIons text="アイデア一覧" size="normal" />
          <IdeaSortNavTexts />
          <UserNavTexts users={props.users} />
          <CategoryNavTexts />
        </nav>
      </main>
    </div>
  );
};

export default SearchTemplateSP;
