import { IdeaActivityType } from '../../../../types/api/idea/IdeaTypes';
import { ActivityCardMolecules } from '../../../molecules/card/activity/ActivityCard.Molecules';
import './IdeaActivities.css';

interface IdeaActivitiesProps {
  idea_activities: IdeaActivityType[];
}

const IdeaActivities: React.FC<IdeaActivitiesProps> = (props) => {
  return (
    <div className="idea-activities-wrapper">
      <div className="idea-activities-cell">
        {props.idea_activities.map((activity) => (
          <ActivityCardMolecules
            key={activity.activity_id}
            activity={activity}
          />
        ))}
      </div>
    </div>
  );
};

export default IdeaActivities;
