import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import DefaultCarouselIons from '../../ions/carousel/DefaultCarousel.Ions';
import { BodyTextIonsClickable } from '../../ions/text/body/BodyTextClickable.Ions';
import { HomeTitleTextIons } from '../../ions/text/title/HomeTitleText.Ions';
import { IdeaCardMolecules } from '../../molecules/card/idea/IdeaCard.Molecules';
import HomeTitleAndSubtitle from '../../molecules/text/title/HomeTitleAndSubtitle';
import './IdeaCarousel.css';

interface IdeaCarouselProps {
  title: string | null;
  subTitle?: string;
  slide_min_width: number;
  card_width: number;
  ideas: IdeaCardType[];
  isSP: boolean;

  type?: 'title' | 'title_with_bg' | 'more';
  isTwoRows?: boolean;
  title_size?: TextSizeType;
  subTitle_size?: TextSizeType;
  button_size?: 'medium' | 'large' | 'extra-large';
  moreOnClick?: () => void;

  playOnInit?: boolean;
}

const IdeaCarousel: React.FC<IdeaCarouselProps> = (props) => {
  const { goToSearchPage } = usePageNavigation();
  return (
    <section className="idea-calousel-bg-wrapper">
      <div className="idea-calousel-with-home-title">
        {(props.type === 'title' || props.type == 'title_with_bg') &&
        props.subTitle &&
        props.title ? (
          <HomeTitleAndSubtitle
            title_size={props.title_size}
            title={props.title}
            subTitle_size={props.subTitle_size}
            subTitle={props.subTitle}
          />
        ) : props.title ? (
          <div className="idea-calousel-title-more">
            <HomeTitleTextIons size={props.title_size} text={props.title} />
            <BodyTextIonsClickable
              text="もっと見る"
              size={props.isSP ? 'small' : 'normal'}
              onClick={props.moreOnClick}
            />
          </div>
        ) : null}

        <DefaultCarouselIons
          slide_min_width={props.slide_min_width}
          isTwoRows={
            props.isTwoRows !== undefined
              ? props.isTwoRows
              : props.isSP
                ? true
                : false
          }
          items={[
            ...props.ideas.map((idea) => (
              <IdeaCardMolecules
                idea={idea}
                hover_effect={!props.isSP}
                width={props.card_width}
                text_size={props.isSP ? 'extra-small' : 'small'}
                display_description={!props.isSP}
              />
            )),
          ]}
          displayButton={false}
          playOnInit={props.playOnInit ?? true}
          autoScroll
        />
      </div>

      {(props.type === 'title' || props.type == 'title_with_bg') && (
        <section className="idea-calousel-button">
          <RoundPurpleButtonIons
            width={props.button_size === 'medium' ? '65%' : '400px'}
            size={props.button_size}
            onClick={() => goToSearchPage({ sort: 'new' })}
          >
            もっと見る
          </RoundPurpleButtonIons>
        </section>
      )}

      {props.type === 'title_with_bg' && <div className="idea-calousel-bg" />}
    </section>
  );
};

export default IdeaCarousel;
