import React from 'react';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import './TextFieldForm.css';

interface ButtonProps {
  title?: string;
}

// クレジットカード情報を入力するフォーム
const CreditCardForm: React.FC<ButtonProps> = (props) => {
  // カード番号
  const {
    value: card_number,
    error: error_card_number,
    handleChange: handleChangeCardNumber,
  } = useTextfield();

  // 有効期限
  const {
    value: expiry_date,
    error: error_expiry_date,
    handleChange: handleChangeExpiryDate,
  } = useTextfield();

  // セキュリティコード
  const {
    value: security_code,
    error: error_security_code,
    handleChange: handleChangeSecurityCode,
  } = useTextfield();

  // カード名義人
  const {
    value: card_holder_name,
    error: error_card_holder_name,
    handleChange: handleChangeCardHolderName,
  } = useTextfield();

  return (
    <section className="textfield-form">
      <DefaultTextFieldIons
        width={'100%'}
        value={card_number}
        onChange={handleChangeCardNumber}
        error={error_card_number}
        label="カード番号"
        placeholder="カード番号を入力してください"
      />
      <div className="textfield-form-row">
        <DefaultTextFieldIons
          value={expiry_date}
          onChange={handleChangeExpiryDate}
          error={error_expiry_date}
          label="有効期限 (MM/YY)"
          placeholder="例: 12/25"
        />
        <DefaultTextFieldIons
          value={security_code}
          onChange={handleChangeSecurityCode}
          error={error_security_code}
          label="セキュリティコード"
          placeholder="例: 123"
        />
      </div>
      <DefaultTextFieldIons
        value={card_holder_name}
        onChange={handleChangeCardHolderName}
        error={error_card_holder_name}
        width={'100%'}
        label="カード名義人"
        placeholder="例: TARO YAMADA"
      />
    </section>
  );
};

export default CreditCardForm;
