import React from 'react';
import { useDispatch } from 'react-redux';
import { openIdeaPostPopup } from '../../../redux/slice/ideaPostPopupSlice';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { UserCardType } from '../../../types/api/user/UserTypes';
import CircleFixedPostButtonIons from '../../ions/button/circle/pink/CircleFixedPostButton.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import IdeaCardGrid from '../../organisms/grid/IdeaCardGrid/IdeaCardGrid';
import CategoryNavTexts from '../../organisms/navTexts/CategoryNavTexts';
import IdeaSortNavTexts from '../../organisms/navTexts/IdeaSortNavTexts';
import UserNavTexts from '../../organisms/navTexts/UserNavTexts';
import './SearchTemplate.css';

interface SearchTemplateProps {
  title?: string;
  size?: 'small' | 'medium' | 'large' | 'none';
  page?: number;
  max_page?: number;
  ideas: IdeaCardType[];
  users: UserCardType[];
}

const SearchTemplatePC: React.FC<SearchTemplateProps> = (props) => {
  const dispatch = useDispatch();
  let width: string;
  let columns: number;

  switch (props.size) {
    case 'small':
      width = '750px';
      columns = 3;
      break;
    case 'medium':
      width = '950px';
      columns = 3;
      break;
    case 'large':
      width = '1100px';
      columns = 4;
      break;
    default:
      width = '1000px';
      columns = 4;
  }

  if (props.size === 'none') {
    return null;
  }

  return (
    <div className="search-template pc">
      <main className="search-template-main" style={{ width: width }}>
        <nav className="search-template-main-nav-texts">
          <IdeaSortNavTexts />
          <UserNavTexts users={props.users} />
          <CategoryNavTexts />
        </nav>
        <section className="search-template-main-ideas">
          {props.title && <BodyTextBoldIons text={props.title} size="medium" />}
          <IdeaCardGrid
            isSP={false}
            card_text_size="extra-small"
            columns={columns}
            ideas={props.ideas}
            page={props.page}
            max_page={props.max_page}
          />
        </section>
      </main>

      <CircleFixedPostButtonIons
        onClick={() => {
          dispatch(openIdeaPostPopup());
        }}
        size={'large'}
      />
    </div>
  );
};

export default SearchTemplatePC;
