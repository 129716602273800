import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { TextSizeType } from '../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../ions/button/round/White/RoundWhiteButton.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { CircleNumTextIons } from '../../ions/text/circle/CircleNumText.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import './IdeaInvestConfirmTemplate.css';

interface IdeaTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaInvestConfirmTemplate: React.FC<IdeaTemplateProps> = ({ size }) => {
  const { goToIdeaPage, goToIdeaInvestPage } = usePageNavigation();
  let width: string;
  const head_title_size: TextSizeType = size === 'sp' ? 'normal' : 'medium';
  let title_size: TextSizeType;
  let text_size: TextSizeType;

  switch (size) {
    case 'sp':
      width = '95%';
      title_size = 'small';
      text_size = 'small';
      break;
    case 'small':
      width = '70%';
      title_size = 'normal';
      text_size = 'small';
      break;
    case 'medium':
      width = '55%';
      title_size = 'normal';
      text_size = 'small';
      break;
    case 'large':
      width = '45%';
      title_size = 'normal';
      text_size = 'small';
      break;
    default:
      width = '45%';
      title_size = 'normal';
      text_size = 'small';
  }

  return (
    <div className="idea-invest-confirm-template">
      <main
        className="idea-invest-confirm-template-main"
        style={{ width: width }}
      >
        <BlackTitleTextIons
          text="内容をご確認の上、「完了」ボタンを押してください。"
          size={head_title_size}
        />
        <section className="idea-invest-confirm-template-main-step">
          <header className="idea-invest-confirm-template-main-step-header">
            <CircleNumTextIons num={1} size={title_size} />
            <BlackTitleTextIons
              text="出資対象: 「アイデアタイトル」"
              size={title_size}
            />
          </header>

          <section className="idea-invest-confirm-template-main-step-content">
            <BodyTextIons
              rows={2}
              size={text_size}
              text="アイデアの詳細。アイデアの詳細。アイデアの詳細。アイデアの詳細。アイデアの詳細。アイデアの詳細。アイデアの詳細。"
            />
          </section>
        </section>

        <section className="idea-invest-confirm-template-main-step">
          <header className="idea-invest-confirm-template-main-step-header">
            <CircleNumTextIons num={2} size={title_size} />
            <BlackTitleTextIons
              text="決済金額: STAGE1 (1000円)"
              size={title_size}
            />
          </header>

          <section className="idea-invest-confirm-template-main-step-content">
            <BodyTextIons
              size={text_size}
              text={
                'アイデアが実現された時に引き落とされます。それまではいつでも取り消しが可能です。'
              }
            />
          </section>
        </section>

        <section className="idea-invest-confirm-template-main-step">
          <header className="idea-invest-confirm-template-main-step-header">
            <CircleNumTextIons num={3} size={title_size} />
            <BlackTitleTextIons text="お客様情報" size={title_size} />
          </header>

          <section className="idea-invest-confirm-template-main-step-content">
            <BodyTextIons size={text_size} text="カード種別: VISA" />
            <BodyTextIons size={text_size} text="名義人: TOTORO TOTORO" />
            <BodyTextIons size={text_size} text="カード有効期限: 01/23" />
            <BodyTextIons
              size={text_size}
              text="カード番号: ****-****-****-1234"
            />
          </section>
        </section>

        <section className="idea-invest-confirm-template-buttons">
          <RoundPurpleButtonIons
            onClick={() => {
              goToIdeaPage('1');
            }}
            size="medium"
          >
            完了
          </RoundPurpleButtonIons>
          <RoundWhiteButtonIons
            onClick={() => {
              goToIdeaInvestPage('1');
            }}
            size="medium"
          >
            戻る
          </RoundWhiteButtonIons>
        </section>
      </main>
    </div>
  );
};

export default IdeaInvestConfirmTemplate;
