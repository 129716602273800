import { Stack } from '@mui/material';
import React from 'react';
import { IdeaCardType } from '../../../types/api/idea/IdeaTypes';
import { RequestDetailType } from '../../../types/api/request/RequestTypes';
import { UserDetailType } from '../../../types/api/user/UserTypes';
import { ButtonSizeType } from '../../atoms/button/Button.type';
import { TextSizeType } from '../../atoms/text/Text.type';
import UserIconImgIons from '../../ions/img/icon/UserIconImg.Ions';
import UserBannerImgIons from '../../ions/img/UserBannerImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import { HomeSubTitleTextIons } from '../../ions/text/subtitle/HomeSubTitle.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import IdeaCarousel from '../../organisms/carousel/IdeaCarousel';
import RequestCarousel from '../../organisms/carousel/RequestCarousel';
import './IntroduceTemplate.css';

interface IntroduceTemplateProps {
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
  user: UserDetailType;
  user_requests: RequestDetailType[];
  user_ideas: IdeaCardType[];
}

const IntroduceTemplatePC: React.FC<IntroduceTemplateProps> = (props) => {
  const { user, size } = props;

  let slide_min_width: number;
  let card_min_width: number;
  let main_width: string;
  let title_size: TextSizeType;
  let subTitle_size: TextSizeType;
  let button_size: ButtonSizeType;

  // レスポンシブ対応
  switch (size) {
    case 'sp':
      slide_min_width = window.innerWidth * 1.0;
      card_min_width = 200;
      main_width = '95%';
      title_size = 'bit-large';
      subTitle_size = 'normal';
      button_size = 'medium';
      break;

    case 'small':
      slide_min_width = 600;
      card_min_width = 240;
      main_width = '95%';
      title_size = 'very-large';
      subTitle_size = 'medium';
      button_size = 'large';
      break;

    case 'medium':
      slide_min_width = 800;
      card_min_width = 300;
      main_width = '92%';
      title_size = 'very-large';
      subTitle_size = 'large';
      button_size = 'large';
      break;

    case 'large':
      slide_min_width = 1000;
      card_min_width = 320;
      main_width = '85%';
      title_size = 'extra-large';
      subTitle_size = 'large';
      button_size = 'extra-large';
      break;

    default:
      slide_min_width = window.innerWidth * 0.6;
      card_min_width = 240;
      main_width = '85%';
      title_size = 'large';
      subTitle_size = 'large';
      button_size = 'extra-large';
  }

  if (size === 'none') {
    return null;
  }

  return (
    <div className="introduce-template">
      <header className="introduce-template-header-banner">
        <UserBannerImgIons img_src={user.banner_img_src || ''} />
      </header>
      <main className="introduce-template-main">
        <section className="introduce-template-main-left">
          <div className="introduce-template-main-left-cell">
            <Stack spacing={2} direction={'row'}>
              <UserIconImgIons
                img_src={user.img_src}
                user_name={user.user_name}
                size="large"
              />
              <HomeSubTitleTextIons
                text={user.user_name}
                size={subTitle_size}
              />
            </Stack>
            <BodyTextIons text={user.description} size="normal" />

            <Stack direction={'row'} spacing={4}>
              <BodyTextIons
                text={'お題投稿数: ' + user.num_of_ideas + ' 件'}
                size="small"
              />
              <BodyTextIons
                text={`獲得した総閲覧数: ${user.num_of_total_pv} pv`}
                size="small"
              />
            </Stack>

            <Stack direction={'row'} spacing={4}>
              <BodyTextIons
                text={'アイデア実現実績: ' + user.num_of_ideas + ' 件'}
                size="small"
              />
              <BodyTextIons
                text={'最近のログイン: ' + user.last_loginedAt + ''}
                size="small"
              />
            </Stack>
          </div>
          <div className="introduce-template-main-left-cell">
            <BlackTitleTextIons text="募集中のお題" size={'medium'} />
            <RequestCarousel
              display_link={true}
              card_width={300}
              slide_min_width={320}
              requests={props.user_requests}
            />
          </div>

          <div className="introduce-template-main-left-cell">
            <BlackTitleTextIons text="いいねしたアイデア" size={'medium'} />
            <IdeaCarousel
              isSP={true}
              title={null}
              isTwoRows={false}
              slide_min_width={180}
              card_width={175}
              ideas={props.user_ideas}
              button_size={button_size}
            />
          </div>

          <div className="introduce-template-main-left-cell">
            <BlackTitleTextIons text="実現したアイデア" size={'medium'} />
            <BodyTextIons text="アイデアの特集" size="normal" />
          </div>
        </section>
        <section className="introduce-template-main-right">
          <div className="introduce-template-main-right-cell">
            <BodyTextBoldIons text="お問い合わせのセル" size="small" />
            <BodyTextIons
              text="会社HPとお問い合わせ情報をここに記載"
              size="small"
            />
          </div>
          <div className="introduce-template-main-right-cell">
            <BodyTextBoldIons text="売っている商品とそのリンク1" size="small" />
            <BodyTextIons
              text="商品紹介とそのリンクボタンをここに記載"
              size="small"
            />
          </div>
          <div className="introduce-template-main-right-cell">
            <BodyTextBoldIons text="売っている商品とそのリンク2" size="small" />
          </div>
          <div className="introduce-template-main-right-cell">
            <BodyTextBoldIons text="売っている商品とそのリンク3" size="small" />
          </div>
        </section>
      </main>
    </div>
  );
};

export default IntroduceTemplatePC;
