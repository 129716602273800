import { EmblaOptionsType } from 'embla-carousel';
import AutoScroll from 'embla-carousel-auto-scroll';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { useCallback, useEffect, useState } from 'react';
import { useDotButton } from './useDotButton.hooks';
import { usePrevNextButtons } from './usePrevNextButtons.hooks';

interface UseCarouselOptions {
  loop?: boolean;
  playOnInit?: boolean;
  delay?: number;
  autoScroll?: boolean;
  onSelectedIndexChange?: (index: number) => void;
  disableDrag?: boolean;
}

export const useCarousel = ({
  loop = true,
  playOnInit = false,
  delay = 3000,
  autoScroll = false,
  onSelectedIndexChange,
  disableDrag = false,
}: UseCarouselOptions = {}) => {
  const [isPlaying, setIsPlaying] = useState(playOnInit);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [wheelSelectedIndex, setWheelSelectedIndex] = useState(0);
  const [wheelScrollSnaps, setWheelScrollSnaps] = useState<number[]>([]);

  const options: EmblaOptionsType = {
    loop,
    skipSnaps: true,
    axis: 'x',
    dragFree: false,
  };

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({
      playOnInit,
      delay,
    }),
    AutoScroll({
      playOnInit: autoScroll,
      startDelay: 30,
      speed: 1.0,
    }),
    WheelGesturesPlugin(),
  ]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);
  const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  // スクロールとボタンの状態管理
  useEffect(() => {
    if (!disableDrag && emblaApi) {
      const autoplay: any = emblaApi?.plugins()?.autoplay;
      const wheelScroll = emblaApi?.plugins()?.wheelGestures;
      if (!autoplay || !wheelScroll) return;

      setIsPlaying(autoplay.isPlaying());

      const onAutoplayPlay = () =>
        setIsPlaying(playOnInit === undefined ? false : playOnInit);
      const onAutoplayStop = () => setIsPlaying(false);
      const onReInit = () => setIsPlaying(autoplay.isPlaying());

      emblaApi
        .on('autoplay:play', onAutoplayPlay)
        .on('autoplay:stop', onAutoplayStop)
        .on('reInit', onReInit);

      const onSelect = () => {
        setWheelSelectedIndex(emblaApi.selectedScrollSnap());
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
      };

      setWheelScrollSnaps(emblaApi.scrollSnapList());
      emblaApi.on('select', onSelect);
      onSelect();

      return () => {
        emblaApi.off('select', onSelect);
        emblaApi.off('autoplay:play', onAutoplayPlay);
        emblaApi.off('autoplay:stop', onAutoplayStop);
        emblaApi.off('reInit', onReInit);
      };
    }
  }, [emblaApi, disableDrag, playOnInit]);

  useEffect(() => {
    if (onSelectedIndexChange) {
      onSelectedIndexChange(selectedIndex);
    }
  }, [selectedIndex, onSelectedIndexChange]);

  const onButtonAutoplayClick = useCallback(
    (callback: () => void) => {
      const autoScroll = emblaApi?.plugins()?.autoScroll;
      const wheelScroll = emblaApi?.plugins()?.wheelGestures;
      if (!autoScroll || !wheelScroll) return;

      const resetOrStop =
        (autoScroll.options as any).stopOnInteraction === false
          ? autoScroll.reset
          : autoScroll.stop;
      resetOrStop();
      callback();
    },
    [emblaApi],
  );

  return {
    emblaRef,
    emblaApi,
    selectedIndex,
    scrollSnaps,
    wheelSelectedIndex,
    wheelScrollSnaps,
    isPlaying,
    prevBtnEnabled,
    nextBtnEnabled,
    onDotButtonClick,
    onPrevButtonClick: () => onButtonAutoplayClick(onPrevButtonClick),
    onNextButtonClick: () => onButtonAutoplayClick(onNextButtonClick),
  };
};
