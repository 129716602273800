import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  DUMMY_REQUEST_DETAIL_DATA,
  MULTI_DUMMY_IDEA_CARD_DATA,
  MULTI_DUMMY_REQUEST_DETAIL_DATA,
} from '../../constants/dummy';
import { RootState } from '../../redux/store';
import { IdeaCardType } from '../../types/api/idea/IdeaTypes';
import { RequestDetailType } from '../../types/api/request/RequestTypes';
import RequestTemplate from '../templates/request/RequestTemplate';

const RequestPage = () => {
  const [searchParams] = useSearchParams();
  const { isXS, isSM, isMD, isLG } = useSelector(
    (state: RootState) => state.screenSize,
  );
  const mode = searchParams.get('mode') || 'detail';
  const page = parseInt(searchParams.get('page') || '1', 10);
  const request: RequestDetailType = DUMMY_REQUEST_DETAIL_DATA;
  const ideas: IdeaCardType[] = MULTI_DUMMY_IDEA_CARD_DATA;
  const other_request: RequestDetailType[] = MULTI_DUMMY_REQUEST_DETAIL_DATA;

  return (
    <RequestTemplate
      size={
        isXS ? 'sp' : isSM ? 'small' : isMD ? 'medium' : isLG ? 'large' : 'none'
      }
      page={page}
      max_page={10}
      mode={mode}
      request={request}
      ideas={ideas}
      other_requests={other_request}
    />
  );
};

export default RequestPage;
