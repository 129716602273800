import React from 'react';
import useTextValue from '../../../hooks/useTextValue';
import RadioButtonAtoms from '../../atoms/radio/RadioButton.Atoms';
import { RadioButtonSizeType } from '../../atoms/radio/RadioButton.type';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import './InvestRadioButtons.css';

interface InvestRadioButtonsProps {
  size?: RadioButtonSizeType;
}

const InvestRadioButtons: React.FC<InvestRadioButtonsProps> = ({ size }) => {
  const { value, handleChange } = useTextValue('1');
  type ValueType = { value: string; title: string; description: string };
  const values: ValueType[] = [
    {
      value: '1',
      title: 'STAGE1 (1000円)',
      description:
        'アイデア実現時に1000円を出資します。小さなリターンを獲得することができます。例. 実現時に10%割引で購入可能(小)',
    },
    {
      value: '2',
      title: 'STAGE2 (5000円)',
      description:
        'アイデア実現時に5000円を出資します。適度なリターンを獲得することができます。例. 実現時に15%割引で購入可能(中)',
    },
    {
      value: '3',
      title: 'STAGE3 (10000円)',
      description:
        'アイデア実現時に10000円を出資します。過度なリターンを獲得することができます。例. 実現時に20%割引で購入可能(大)',
    },
  ];

  return (
    <section className="invest-radio-button-wrapper">
      {values.map((v: ValueType) => (
        <RadioButtonAtoms
          checked={v.value === value}
          size={size}
          onChange={() => {
            handleChange(v.value);
          }}
        >
          <div
            className={`radio-button-cell-invest ${v.value === value ? 'selected' : ''} ${size}`}
          >
            <BodyTextBoldIons text={v.title} size={'small'} />
            <BodyTextIons text={v.description} size={'extra-small'} />
          </div>
        </RadioButtonAtoms>
      ))}
    </section>
  );
};

export default InvestRadioButtons;
