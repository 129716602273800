import React from 'react';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import useTextValues from '../../../../hooks/useTextValues';
import { UserDetailType } from '../../../../types/api/user/UserTypes';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultTextFieldIons from '../../../ions/textfield/DefaultTextField.Ions';
import TagsCheckboxGrid from '../../grid/tagCheckboxGrid/TagsCheckboxGrid';
import './TextFieldForm.css';

interface ButtonProps {
  columns: number;
  user: UserDetailType;
}

// お届け先情報を入力するテキストフィールド
const UserInfoForm: React.FC<ButtonProps> = (props) => {
  const { user } = props;
  // ユーザーネーム
  const {
    value: user_name,
    error: error_user_name,
    handleChange: handleChangeUsername,
  } = useTextfield(user.user_name);

  // メールアドレス
  const {
    value: mail,
    error: error_mail,
    handleChange: handleChangeMail,
  } = useTextfield(user.mail);

  // ユーザー説明
  const {
    value: description,
    error: error_description,
    handleChange: handleChangeDescription,
  } = useTextfield(user.description);

  // SNSリンク
  const {
    value: sns_link,
    error: error_sns_link,
    handleChange: handleChangeSNSLink,
  } = useTextfield(user.sns_link);

  // カテゴリーの値
  const { values: tags, handleChange: handleTagsChange } = useTextValues(
    user.tags,
  );

  return (
    <section className="textfield-form">
      <div className="textfield-form-row">
        <DefaultTextFieldIons
          value={user_name}
          onChange={handleChangeUsername}
          error={error_user_name}
          label="ユーザーネーム"
          placeholder="ユーザーネームを入力してください"
        />
        <DefaultTextFieldIons
          value={mail}
          onChange={handleChangeMail}
          error={error_mail}
          label="メールアドレス"
          placeholder="メールアドレスを入力してください"
        />
      </div>
      <DefaultTextFieldIons
        value={description}
        onChange={handleChangeDescription}
        error={error_description}
        width={'100%'}
        rows={5}
        label="自己紹介"
        placeholder="自己紹介を入力してください"
      />

      <DefaultTextFieldIons
        value={sns_link}
        onChange={handleChangeSNSLink}
        error={error_sns_link}
        width={'100%'}
        label="SNSリンク"
        placeholder="SNSリンクを入力してください"
      />

      <TagsCheckboxGrid
        tags={tags}
        error={null}
        handleChange={handleTagsChange}
        columns={props.columns}
        require={null}
      />

      <section className="user-info-form-buttons">
        <RoundPurpleButtonIons size="medium" width={'50%'}>
          保存する
        </RoundPurpleButtonIons>
        <RoundWhiteButtonIons size="medium" width={'50%'}>
          最初に戻す
        </RoundWhiteButtonIons>
      </section>
    </section>
  );
};

export default UserInfoForm;
