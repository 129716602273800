import React from 'react';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { IdeaDetailType } from '../../../types/api/idea/IdeaTypes';
import { TextSizeType } from '../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../ions/button/round/Purple/RoundPurpleButton.Ions';
import IdeaImgIons from '../../ions/img/IdeaImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { CircleNumTextIons } from '../../ions/text/circle/CircleNumText.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import UserIconAndName from '../../molecules/user/UserIconAndName';
import InvestRadioButtons from '../../organisms/radio/InvestRadioButtons';
import CreditCardForm from '../../organisms/textfield/form/CreditCardForm';
import './IdeaInvestTemplate.css';

interface IdeaTemplateProps {
  idea: IdeaDetailType;
  size?: 'sp' | 'small' | 'medium' | 'large' | 'none';
}

const IdeaInvestTemplate: React.FC<IdeaTemplateProps> = (props) => {
  const { idea, size } = props;
  const { goToIdeaInvestConfirmPage } = usePageNavigation();
  let width: string;
  let title_size: TextSizeType;
  let text_size: TextSizeType;
  let subText_size: TextSizeType;
  let radio_buttons_size: TextSizeType;

  switch (size) {
    case 'sp':
      width = '95%';
      title_size = 'normal';
      text_size = 'normal';
      subText_size = 'small';
      radio_buttons_size = 'small';
      break;
    case 'small':
      width = '70%';
      title_size = 'normal';
      text_size = 'small';
      subText_size = 'small';
      radio_buttons_size = 'small';
      break;
    case 'medium':
      width = '55%';
      title_size = 'normal';
      text_size = 'small';
      subText_size = 'small';
      radio_buttons_size = 'medium';
      break;
    case 'large':
      width = '45%';
      title_size = 'medium';
      text_size = 'normal';
      subText_size = 'small';
      radio_buttons_size = 'large';
      break;
    default:
      width = '45%';
      title_size = 'medium';
      text_size = 'normal';
      subText_size = 'small';
      radio_buttons_size = 'large';
  }

  return (
    <div className="idea-invest-template">
      <main className="idea-invest-template-main" style={{ width: width }}>
        <section className="idea-invest-template-main-step">
          <header className="idea-invest-template-main-step-header">
            <CircleNumTextIons num={1} size={title_size} />
            <BlackTitleTextIons
              text="出資するアイデアを確認する"
              size={title_size}
            />
          </header>

          <section
            className={`idea-invest-template-main-step-content ${size === 'sp' ? 'sp' : ''}`}
          >
            <IdeaImgIons
              img_src={idea.img_src}
              className={`idea-invest-template-img ${size === 'sp' ? 'sp' : ''}`}
            />
            <div
              className={`idea-invest-template-main-step-content-texts ${size === 'sp' ? 'sp' : ''}`}
            >
              <BlackTitleTextIons text={idea.title} size={text_size} />
              <BodyTextIons
                rows={3}
                text={idea.description}
                size={subText_size}
              />
              <UserIconAndName
                user_img_src={idea.user_img_src}
                user_name={idea.user_name}
                icon_size="small"
              />
            </div>
          </section>
        </section>

        <section className="idea-invest-template-main-step">
          <header className="idea-invest-template-main-step-header">
            <CircleNumTextIons num={2} size={title_size} />
            <BlackTitleTextIons text="出資金額を確認する" size={title_size} />
          </header>

          <section className="idea-invest-template-main-step-content">
            <InvestRadioButtons size={radio_buttons_size} />
          </section>
        </section>

        <section className="idea-invest-template-main-step">
          <header className="idea-invest-template-main-step-header">
            <CircleNumTextIons num={3} size={title_size} />
            <BlackTitleTextIons
              text="クレジットカード情報を登録する"
              size={title_size}
            />
          </header>

          <section className="idea-invest-template-main-step-content">
            <CreditCardForm />
          </section>
        </section>

        <RoundPurpleButtonIons
          onClick={() => {
            goToIdeaInvestConfirmPage('1');
          }}
          size="medium"
          width={'60%'}
        >
          支払い確認へ進む
        </RoundPurpleButtonIons>
      </main>
    </div>
  );
};

export default IdeaInvestTemplate;
