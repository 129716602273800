import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createIdea } from '../../../../api/idea/createIdeaAPI';
import { MULTI_DUMMY_REQUEST_DETAIL_DATA } from '../../../../constants/dummy';
import { IDEA_TAGS_MAX_LENGTH } from '../../../../constants/max';
import { openLoginPopup } from '../../../../redux/slice/loginPopupSlice';
import { RootState } from '../../../../redux/store';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import RoundWhiteButtonIons from '../../../ions/button/round/White/RoundWhiteButton.Ions';
import DefaultPopupIons from '../../../ions/popup/DefaultPopup.Ions';
import NumAndTitle from '../../../molecules/text/numTitle/NumAndTitle';
import RequestCarousel from '../../../organisms/carousel/RequestCarousel';
import TagsCheckboxGrid from '../../../organisms/grid/tagCheckboxGrid/TagsCheckboxGrid';
import IdeaImageGeneForm from '../../../organisms/textfield/form/IdeaImageGene/IdeaImageGeneForm';
import IdeaStoryTextfield from '../../../organisms/textfield/story/IdeaStoryTextfield';
import IdeaTitleTextfield from '../../../organisms/textfield/title/IdeaTitleTextfield';
import './IdeaPostPopup.css';
import { useIdeaPostForm } from './useIdeaPostForm.hooks';

interface PopupProps {
  isSP: boolean;
  open: boolean;
  onClose: () => void;
}

const IdeaPostPopup: React.FC<PopupProps> = React.memo(
  ({ isSP, open, onClose }) => {
    const dispatch = useDispatch();
    // ログイン状態の取得
    const { isLoggedIn } = useSelector((state: RootState) => state.auth);

    // ラッピングされたフォーム値
    const { values, errors, handlers } = useIdeaPostForm();

    // アイデア作成処理
    const handleCreateIdea = useCallback(() => {
      if (!isLoggedIn) {
        dispatch(openLoginPopup());
      } else {
        createIdea({
          request_id: values.request_id,
          title: values.title,
          description: values.story,
          tags: values.tags,
          img_src: '',
          generate: values.generate,
        });
      }
    }, [isLoggedIn, values]);

    return (
      <DefaultPopupIons
        open={open}
        onClose={onClose}
        size={isSP ? 'large' : 'medium'}
      >
        <div className="idea-popup">
          <div className="idea-popup-main">
            <section className="idea-popup-main">
              <main className="idea-popup-main-container">
                <div className="idea-popup-main-container-request-carousel">
                  <NumAndTitle
                    num={1}
                    title="お題を選ぶ (未選択も可)"
                    num_size="small"
                    title_size="small"
                  />
                  <RequestCarousel
                    setRequestID={handlers.handleRequestIdChange}
                    display_link={true}
                    card_width={300}
                    slide_min_width={320}
                    requests={MULTI_DUMMY_REQUEST_DETAIL_DATA}
                    otherTransparent={true}
                    playOnInit={false}
                    isBanner={isSP}
                  />
                </div>

                <IdeaTitleTextfield
                  num={2}
                  require={null}
                  title={values.title}
                  error={errors.title}
                  handleChange={handlers.handleTitleChange}
                  size={isSP ? 'small' : 'medium'}
                />
                <IdeaStoryTextfield
                  num={3}
                  require={null}
                  story={values.story}
                  error={errors.story}
                  handleChange={handlers.handleStoryChange}
                  size={isSP ? 'small' : 'medium'}
                />

                <TagsCheckboxGrid
                  num={4}
                  require={null}
                  max={IDEA_TAGS_MAX_LENGTH}
                  columns={isSP ? 3 : 4}
                  tags={values.tags}
                  error={errors.tags}
                  handleChange={handlers.handleTagsChange}
                />
                <IdeaImageGeneForm
                  num={5}
                  text_size={isSP ? 'extra-small' : 'small'}
                  generate={values.generate}
                  handleChangeGenerate={handlers.handleChangeGenerate}
                  img={values.img}
                  handleChangeImg={handlers.handleChangeImg}
                />
              </main>
            </section>

            <div className="form-submit-button">
              <RoundPurpleButtonIons
                width="75%"
                onClick={handleCreateIdea}
                size="normal"
              >
                アイデアページを作成
              </RoundPurpleButtonIons>
              <RoundWhiteButtonIons
                width="75%"
                onClick={handleCreateIdea}
                size="normal"
              >
                下書き保存する
              </RoundWhiteButtonIons>
            </div>
          </div>
        </div>
      </DefaultPopupIons>
    );
  },
);

export default IdeaPostPopup;
