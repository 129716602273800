import { ChangeEvent, useCallback, useState } from 'react';

interface UseTextfieldProps {
  value: string;
  error: string | null;
  handleChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
}

const useTextfieldMax = (init?: string, max?: number): UseTextfieldProps => {
  const [value, setValue] = useState(init ?? '');
  const [error, setError] = useState<string | null>(null);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      if (max && e.target.value.length > max) {
        setError(`最大${max}文字までです`);
        return;
      } else {
        setValue(e.target.value);
        setError(null);
      }
    },
    [],
  );

  return { value, error, handleChange };
};

export default useTextfieldMax;
