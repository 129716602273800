import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { TextSizeType } from '../../atoms/text/Text.type';
import RequestImgIons from '../../ions/img/RequestImg.Ions';
import { BodyTextIonsClickable } from '../../ions/text/body/BodyTextClickable.Ions';
import { BlackTitleTextIons } from '../../ions/text/title/BlackTitleText.Ions';
import './RequestCard.css';

interface TagsCheckboxGridProps {
  request_id: string | null;
  img_src: string | null;
  title: string | null;
  title_size?: TextSizeType;
}

const RequestCard: React.FC<TagsCheckboxGridProps> = (props) => {
  const { goToRequestPage } = usePageNavigation();
  return (
    <div className="request-card-wrapper">
      <BlackTitleTextIons text="お題" size={props.title_size ?? 'normal'} />
      <div
        className="request-card-wrapper-imgs"
        onClick={() => goToRequestPage()}
      >
        <RequestImgIons
          img_src={props.img_src || 'images/static/REQUEST-NULL.png'}
        />
        {props.title && (
          <BodyTextIonsClickable text={'「' + props.title + '」を詳しく見る'} />
        )}
      </div>
    </div>
  );
};

export default RequestCard;
