import React from 'react';
import { TextSizeType } from '../../atoms/text/Text.type';
import UserIconImgIons from '../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import './UserIconAndName.css';

interface UserIconAndNameProps {
  user_name: string;
  user_img_src: string;
  icon_size?: 'extra-small' | 'small' | 'medium' | 'large';
  text_size?: TextSizeType;
  bold?: boolean;
  onClick?: () => void;
}

const UserIconAndName: React.FC<UserIconAndNameProps> = React.memo((props) => {
  return (
    <section className="user-icon-and-name-wrapper" onClick={props.onClick}>
      <UserIconImgIons
        user_name={props.user_name}
        img_src={props.user_img_src}
        size={props.icon_size}
      />
      {props.bold ? (
        <BodyTextBoldIons
          text={props.user_name}
          size={props.text_size ?? 'small'}
        />
      ) : (
        <BodyTextIons
          text={props.user_name}
          size={props.text_size ?? 'small'}
        />
      )}
    </section>
  );
});

export default UserIconAndName;
