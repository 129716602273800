// ログイン用の型定義

import { mailLoginInputType } from './mailLoginAPI.type';

// ログインAPI
export const mailLogin = async (input: mailLoginInputType) => {
  // const response = await fetch(`/api/login/`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(input),
  // });
  // if (!response.ok) {
  //   throw new Error(`Login failed: ${response.statusText}`);
  // }
  // return response.json();
};
