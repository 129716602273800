import React from 'react';
import { SNSCircleButton } from '../../../ions/sns/circle/SNSCircleButton';
import './SNSCircleButtons.css';

interface SNSButtonsProps {
  size?: 'small' | 'medium' | 'large';
}

const SNSCircleButtons: React.FC<SNSButtonsProps> = React.memo((props) => {
  const TYPE = [
    'Line',
    'Linkedin',
    'Facebook',
    'X',
    'Insta',
    'Hatena',
    'Email',
  ];
  return (
    <section className="sns-circle-buttons-molecules">
      {TYPE.map((type) => (
        <SNSCircleButton
          key={type}
          type={type as any}
          size={props.size}
          onClick={() => {
            alert('onClick');
          }}
        />
      ))}
    </section>
  );
});

export default SNSCircleButtons;
