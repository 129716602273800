import React from 'react';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import UserIconImgIons from '../../../ions/img/icon/UserIconImg.Ions';
import { BodyTextIons } from '../../../ions/text/body/BodyText.Ions';
import { BodyTextBoldIons } from '../../../ions/text/body/BodyTextBold.Ions';
import { CircleNumTextIons } from '../../../ions/text/circle/CircleNumText.Ions';
import './RoomCard.Molecules.css';

type RoomCardProps = {
  room_id: string;
  user_id: string;
  user_name: string;
  user_img_src: string | null;
  last_message: string;
  last_created_at: string;
  unread_count: number;
};

export const RoomCardMolecules: React.FC<RoomCardProps> = React.memo(
  (props) => {
    const { goToMyPage } = usePageNavigation();
    return (
      <div
        className="room-card-molecules-container"
        onClick={() =>
          goToMyPage({ mode: 'chat', chat_user_id: props.user_id })
        }
      >
        <div className="room-card-icons-and-name">
          <UserIconImgIons
            user_name={props.user_name}
            img_src={props.user_img_src}
            size="large"
          />
          <div className="room-card-user-name-message">
            <BodyTextBoldIons text={props.user_name} size="small" />
            <BodyTextIons
              text={props.last_message}
              rows={1}
              size="extra-small"
            />
          </div>
        </div>
        <div className="room-card-time-unread">
          <BodyTextIons text={props.last_created_at} size="extra-small" />
          <CircleNumTextIons num={props.unread_count} size="small" />
        </div>
      </div>
    );
  },
);
