import { mailLogin } from '../../api/auth/mailLoginAPI';
import { mailLoginInputType } from '../../api/auth/mailLoginAPI.type';
import { DUMMY_USER_CARD_DATA } from '../../constants/dummy';
import { login } from '../auth/authSlice';
import { AppDispatch } from '../store';
import { loginUser } from './userSlice';

export const loginWithMail =
  (input: mailLoginInputType) =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    try {
      await mailLogin(input); // API 呼び出し
      dispatch(login('')); // 成功時に Redux ストアを更新
      dispatch(loginUser(DUMMY_USER_CARD_DATA));
      return true; // ログイン成功
    } catch (error) {
      return false; // ログイン失敗
    }
  };
