import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useOpen from '../../../../hooks/useOpen';
import { usePageNavigation } from '../../../../hooks/usePageNavigation';
import useTextfield from '../../../../hooks/useTextfield.hooks';
import { ChatDetailType } from '../../../../types/api/chat/ChatTypes';
import { TextSizeType } from '../../../atoms/text/Text.type';
import RoundPurpleButtonIons from '../../../ions/button/round/Purple/RoundPurpleButton.Ions';
import { BodySubTextIons } from '../../../ions/text/body/BodySubText.Ions';
import { BodyTextIonsClickable } from '../../../ions/text/body/BodyTextClickable.Ions';
import DefaultInputAdorementTextField from '../../../ions/textfield/DefaultInputAdorementTextField';
import { ChatMolecules } from '../../../molecules/chat/Chat.Molecules';
import ApplyConfirmPopup from '../../popup/ApplyConfirmPopup';
import './ChatGrid.css';

interface TagsCheckboxGridProps {
  to_user_name: string;
  login_user_id: string;
  chats: ChatDetailType[];
  size?: TextSizeType;
  only?: boolean;
}

const ChatGrid: React.FC<TagsCheckboxGridProps> = (props) => {
  const { goBack } = usePageNavigation();
  const { value, handleChange } = useTextfield();
  const { open, handleOpen, handleClose } = useOpen();
  return (
    <div
      data-testid="chat-grid"
      className={`chat-grid-container ${props.only ? 'only' : ''}`}
    >
      <section className={`chat-grid-and-input ${props.only ? 'only' : ''}`}>
        <header className="chat-grid-header">
          <div className="chat-grid-header-back-and-user">
            <ArrowBackIosIcon sx={{ color: 'grey' }} onClick={goBack} />
            <BodySubTextIons text={props.to_user_name} size="small" />
          </div>
          <RoundPurpleButtonIons onClick={handleOpen}>
            商品化を決定
          </RoundPurpleButtonIons>
        </header>
        <div className={`chat-grid ${props.only ? 'only' : ''}`}>
          {props.chats.map((chat, index: number) => (
            <ChatMolecules
              user_img_src={chat.user_img_src}
              user_name={chat.user_name}
              left={chat.user_id !== props.login_user_id}
              createdAt={chat.createdAt}
              size={props.size}
            />
          ))}
        </div>
        <div className={`chat-grid-input ${props.only ? 'only' : ''}`}>
          <DefaultInputAdorementTextField
            placeholder="メッセージを入力"
            value={value}
            onChange={handleChange}
          />
          <div className="chat-grid-input-buttons">
            <BodyTextIonsClickable size="extra-small" text="キャンセル" />
            <BodyTextIonsClickable size="extra-small" text="送信する" />
          </div>
        </div>
      </section>
      <ApplyConfirmPopup open={open} onClose={handleClose} />
    </div>
  );
};

export default ChatGrid;
