import { DUMMY_TAGS } from '../../../constants/dummy';
import { usePageNavigation } from '../../../hooks/usePageNavigation';
import { BodyTextBoldIons } from '../../ions/text/body/BodyTextBold.Ions';
import NavTextsMolecules from '../../molecules/text/nav/NavTexts.Molecules';
import './NavTexts.css';

const CategoryNavTexts = () => {
  const { goToSearchPage } = usePageNavigation();
  return (
    <section className="with-title-nav-texts">
      <BodyTextBoldIons text="カテゴリ一覧" size="small" />
      <NavTextsMolecules
        textOptions={DUMMY_TAGS.map((tag) => ({
          label: tag.name,
          value: tag.tag_id,
        }))}
        onClick={(value: string) => goToSearchPage({ category_id: value })}
        value={''}
      />
    </section>
  );
};

export default CategoryNavTexts;
