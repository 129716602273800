import {
  fetchIdeaCommentsProps,
  sendIdeaCommentProps,
} from './ideaCommentAPI.type';

// アイデアのコメントを取得するAPI
export const fetchIdeaComments = async (input: fetchIdeaCommentsProps) => {
  const response = await fetch(`/api/ideas/${input.idea_id}/comments`);

  if (!response.ok) {
    throw new Error(`Failed to fetch comments: ${response.statusText}`);
  }

  return response.json();
};

// アイデアのコメントを送信するAPI
export const sendIdeaComment = async (input: sendIdeaCommentProps) => {
  const response = await fetch(`/api/ideas/${input.idea_id}/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(input),
  });

  if (!response.ok) {
    // throw new Error(`Failed to send comment: ${response.statusText}`);
    alert('Input:\n' + String(JSON.stringify(input)));
  }

  return response.json();
};
